@import '../_colors.scss';

.game-settings-wrapper{
  display: flex;
  flex-direction: column;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  
  .disable-setting-modal{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: none; 
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10000;
    cursor: not-allowed;
  }
}

.game-tasks-wrapper{
  display: flex;
  flex-direction: column;
  max-height: 500px;
  ::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $scroll_bar; 
  }
}

.tabs_flex_container {
  justify-content: space-around;
  align-items: center;
  background-color: rgb(0, 188, 212);
  color: #fff;
  font-size: 16px;
  .tab-header {
    width: 50%;
    padding: 0;
    margin: 0;
    background-color: transparent; 
  }
}

.game-settings-buttons-line{
  display: flex;
  flex-direction: row;
  margin: 10px auto;
  height: 50px;
  &.saves{
    align-items: flex-end;
    margin-bottom: 20px;
  }
}

.game-settings-buttons-line-button{
  width: 50px;
  margin: 0px 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;

  &.resetDefault{
    background-image: url(/images/TaskSettingsDefault_50px.png);
  }
  &.flipMovementH{
    background-image: url(/images/TaskSettingsFlipMovementL2R_50px.png);
  }
  &.unFlipMovementH{
    background-image: url(/images/TaskSettingsFlipMovementR2L_50px.png);
  }
  &.flipMovementV{
    background-image: url(/images/TaskSettingsFlipMovementH2L_50px.png);
  }
  &.unFlipMovementV{
    background-image: url(/images/TaskSettingsFlipMovementL2H_50px.png);
  }
  &.flipViewR{
    background-image: url(/images/TaskSettingsFlipViewL2R_50px.png);
  }
  &.flipViewL{
    background-image: url(/images/TaskSettingsFlipViewR2L_50px.png);
  }
  &.effectsMute{
    background-image: url(/images/TaskSettingsSoundFBOff_50px.png);
  }
  &.effectsUnmute{
    background-image: url(/images/TaskSettingsSoundFBOn_50px.png);
  }
  &.musicMute{
    background-image: url(/images/TaskSettingsSoundBGOff_50px.png);
  }
  &.musicUnmute{
    background-image: url(/images/TaskSettingsSoundBGOn_50px.png);
  }
  &.autoOn{
    background-image: url(/images/TaskSettingsAutomaticOn_50px.png);
  }
  &.autoOff{
    background-image: url(/images/TaskSettingsAutomaticOff_50px.png);
  }
  
  &.disabled{
    opacity: 0.5;
    cursor: default;
  }
}

.game-setting-box-content{
  position: relative;
  text-align: center;
  height: calc(100% - 45px);
  width: 100%;
  background-color: white;
  display: flex;
  z-index: 1;
  flex-direction: column;
}

.game-setting-box-content-disabler{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.4;
  z-index: 2;
  cursor: default;
}

.game-settings-save-window-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px;
}

.game-settings-save-window-warning {
  position: relative;
  padding-left: 140px;
}

.game-settings-save-window-task-name{
    font-size: 16px;
    width: 256px;
    height: 28px;
    display: inline-block;
    line-height: 24px;
    background-color: transparent;
    font-family: Roboto, sans-serif;
    transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    cursor: auto;
    border: none;
    border-bottom: 1px lightgray solid;
    margin-right: 30px;
    margin-left: 30px;
}

.game-settings-save-window-task-name:focus{
  border-bottom: 1px turquoise solid;
}

.game-tasks-header{
  width: 32px;
  background-repeat: no-repeat;
  background-position: center;
  &.date{
    background-image: url(/images/TaskList_InsertDate_32px.png);
    border: none;
  }
  &.name{
    background-image: url(/images/TaskList_Instruction_32px.png);
    border: none;
  }
  &.joint{
    background-image: url(/images/TaskList_Joint_32px.png);
    border: none;
  }
  &.bodypos{
    background-image: url(/images/TaskList_Axis_32px.png);
    border: none;
  }
  &.duration{
    background-image: url(/images/TaskList_Duration_32px.png);
    border: none;
  }
}

.game-settings-icon_wrapper {
  max-width: 88px;
}

.game-tasks-change-wrapper{
  padding-right: 10px;
  min-width: 40px;
}

.game-tasks-change-title {
  padding-bottom: 10px;
  font-size: 16px;
  text-transform: capitalize;
}

.game-tasks-delete-button{
  width: 17px;
  height: 17px;
  background-image: url(/images/DeleteBTdevice.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  
  &.deleted{
    text-decoration-line: line-through;
  }
}

.game-setting-range-selector{
  padding-top: 15px;
  width: 90%;
  height: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.game-setting-range-selector-color{
  color: #669AFF !important;
}

.game-setting-range-selector-pre{
  font-size: 20px;
  color: #669AFF;
  padding-right: 7px;
  display: flex;
  flex-direction: column-reverse;
}

.game-setting-range-selector-slider{
  width: 100%;
  margin-bottom: -48px;
  margin-top: -14px;
  flex-grow: 1;

  .MuiSlider-thumbColorPrimary{
    background-color: #00bcd4;
  }
}

.game-setting-range-selector-text{
  text-align: left;
  min-width: 90px;
  font-size: 25px;
  letter-spacing: 1px;
  color: $screen_background;
  //border: 2px solid #b7c7da;
  margin-left: 15px;
  margin-top: -16px;
}


.game-setting-3ss{
  width: 90%;
  height: 80%;
  margin: auto;
  display: flex;
}

.game-setting-3ss-slider{
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  /**/margin-top: 17px;
  /**/height: 2px;
  &.left{
    //background-image: linear-gradient(to right, #9dc7f9 50%, #e25d5e 50%);
    /**/background-image: linear-gradient(to right, rgb(0, 188, 212) 50%,  rgb(189, 189, 189) 50%);
  }
  &.full{
    //background-color: #9dc7f9
    /**/background-color: rgb(0, 188, 212)
  }
  &.right{
    //background-image: linear-gradient(to right, #e25d5e 50%, #9dc7f9 50%);
    /**/background-image: linear-gradient(to right, rgb(189, 189, 189) 50%, rgb(0, 188, 212) 50%);
  }
  &::-webkit-slider-thumb{
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgb(0, 188, 212);
    cursor: pointer;
    transition: background .15s ease-in-out;
    &:active {
      transform: scale(1.4); 
    }
  }
  &::-moz-range-thumb{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgb(0, 188, 212);
    cursor: pointer;
    transition: background .15s ease-in-out;
    &:active {
      transform: scale(1.4); 
    }
  }
  &::-ms-thumb{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgb(0, 188, 212);
    cursor: pointer;
    transition: background .15s ease-in-out;
    &:active {
      transform: scale(1.4); 
    }
  }
}

.game-setting-3ss-text{
  text-align: left;
  min-width: 90px;
  font-size: 25px;
  letter-spacing: 1px;
  color: $screen_background;
  //border: 2px solid #b7c7da;
  margin-left: 15px;
}



.game-setting-4ss{
  width: 90%;
  height: 80%;
  margin: auto;
  display: flex;
}

.game-setting-4ss-slider{
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  /**/margin-top: 17px;
  /**/height: 2px;
  background-color: rgb(189, 189, 189);
  &::-webkit-slider-thumb{
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgb(0, 188, 212);
    cursor: pointer;
    transition: background .15s ease-in-out;
    &:active {
      transform: scale(1.4); 
    }
  }
  &::-moz-range-thumb{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgb(0, 188, 212);
    cursor: pointer;
    transition: background .15s ease-in-out;
    &:active {
      transform: scale(1.4); 
    }
  }
  &::-ms-thumb{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgb(0, 188, 212);
    cursor: pointer;
    transition: background .15s ease-in-out;
    &:active {
      transform: scale(1.4); 
    }
  }
}

.game-setting-4ss-text{
  text-align: left;
  min-width: 90px;
  font-size: 25px;
  letter-spacing: 1px;
  color: $screen_background;
  //border: 2px solid #b7c7da;
  margin-left: 15px;
}



.game-setting-color-picker{
    margin: 0 auto;
}

.game-setting-color-picker-plate-wrapper{
  padding-left: 10px;
  padding-top: 10px;
  margin-right: 14px;
  padding-bottom: 20px;
}

.game-setting-color-picker-button-wrapper{
  width: 28px;
  height: 28px;
  margin: 0 auto;
  transform: scale(1); 
  transition: transform 100ms ease;
  &:hover {
    transform: scale(1.2); 
  }
}

.game-setting-color-picker-button{
  background: transparent; 
  height: 100%; 
  width: 100%; 
  cursor: pointer; 
  position: relative; 
  border-radius: 50%; 
  transition: box-shadow 100ms ease;
}

.MuiIconButton-root:hover {
  border-radius: 0;
}

.game-difficulty{
  width: 90%;
  height: 80%;
  margin: auto;
  display: flex;
}

.single-difficulty-container{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  cursor: pointer;
  &.medium{
    position: relative;
    left: -4px;
  }
  &.hard{
    position: relative;
    left: -8px;
  }
}

.difficulty-title{
  height: 20px;
  padding-bottom: 20px;
  font-size: 20px;
  &.easy{
    color: #9dc7f9;
  }
  &.medium{
    color: #7ad79e;
  }
  &.hard{
    color: #e25d5e;
  }
}

.game-difficulty-slider-container{
  width: 100%;
  height: 16px;
  position: relative;
}

.difficulty-slider{
  width: 100%;
  height: 8px;
  border-radius: 4px;
  &.easy{
    background-color: #b9d7f9;
  }
  &.medium{
    background-color: #83d3ae;
  }
  &.hard{
    background-color: #eeafa8;
  }
}

.chosen-difficulty-indicator{
  width: 16px;
  height: 16px;
  border-radius: 8px;
  position: absolute;
  left: calc(50% - 8px);
  top: -4px;
  &.easy{
    background-color: #9dc7f9;
  }
  &.medium{
    background-color: #7ad79e;
  }
  &.hard{
    background-color: #e25d5e;
  }
}

.choose-duration-wrapper{
  height: 50%;
  margin: auto;
  display: flex;
}

.choose-duration{
  width: 140px;
  font-size: 40px;
  letter-spacing: 4px;
  color: $screen_background;
  border-bottom: 1px solid #b7c7da;
}

.choose-duration-buttons-wrapper{
  display: flex;
  flex-direction: column;
  width: calc(100% - 140px);
}

.choose-duration-button{
  margin-left: 10px;
  cursor: pointer;
  background-image: url(/images/Arrow_down.png);
  width: 14px;
  height: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  &.up{
    background-image: url(/images/Arrow_up.png);  
  }
  &.disabled{
    cursor: not-allowed;
  }
}
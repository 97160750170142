@import '../_colors.scss';

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

body
{
  font-family: "proxima-nova",sans-serif;
  font-style: normal;
}

// .headersiteaccount{
  
//   text-align: left;
//   font-size: 18px;
//   margin-top: 4%;
//   margin-bottom: 1%;
//   font-family: "proxima-nova",sans-serif;
//   text-indent: 2%;
//   font-weight: bold;
// }


.sectioncat{

  text-align: left;
  float: left;
  width: 300px;
  height: 50px;
  text-indent: 3%;
  line-height: 50px;
  vertical-align: middle;
  font-size: 16px;
 
  
}
.cathead
{
  text-align: center;
  float: left;
  width: 80px;
  height: 40px;
  border-radius: 3px;
  /* border: 1px solid silver; */
  margin-left: 20px;
  border-radius: 10px;
  line-height: 40px;
  font-size: 16px;
}
  .sectioncatdata
  {
 
    float: left;
    width: 300px;
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
    text-align: center;
  }

.sectioncatdatabox
{

  text-align: center;
  float: left;
  width: 70px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #565d69;
  margin-left: 20px;
  border-radius: 10px;
  line-height: 40px;
  background: #c1d5f7;

}
.title
{
  font-size: 22px;
  color: #5179A5;
  text-transform: uppercase;
}
.total
{
  color: #71b2fd;
  font-size: 22px;
  text-transform: uppercase;
}
  .registeredonline{
    float: left;
  }
  .clear{
    clear: both;
    margin-top: 10px;
  }

.bodytbl::-webkit-scrollbar-track
{
  background-color: transparent;
  border-radius: 10px;
}

.bodytbl::-webkit-scrollbar
{
  width: 7px;
  background-color: transparent;
}

.bodytbl::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  background-color: #5179a5;
  cursor: pointer;
}

.checkboxoption
{

  display: inline-block;
  width: 400px;
  height: 20px;
  vertical-align: top;
}
.actionbtns
{
  margin-left: 60%;
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
}

div.roweven:nth-child(odd) 
{
  background:#F7FAFF
}

.buttonoption.cancel {
  margin-top: 2px;
  background-color: transparent;
  border: 2px solid #3EC880;
  color: #3EC880;
  height: 49px;
}



.buttonoption.disabled {
  cursor: default;
  opacity: 0.5;
}

// .header div{
//   width: 100%;
//   text-align: left;
//   padding-left: 10px;
//   cursor: pointer;
//   vertical-align: middle;
//   line-height: 40px;
// }

// .data-row
// {

//   width: 33%;
//   font-size: 17px;
//   text-transform: capitalize;
//   line-height: 40px;
//   padding-left: 25px;
//   color: #9eb1c6 !important;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
//   padding-right: 23px;

 
// }

.roweven
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.header {
    width: 100%;
    height: 40px;
    background-color: #5179a5;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
}

.buttonoption
{    
  height: 49px;
  width: 124px;
  background-color: #3EC880;
  margin-left: 25px;
  color: #FFFFFF;
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
  line-height: 49px;
  border: none;
  cursor: pointer;
}
.checkboxoptionbtnspn
{
  display: inline-block; 
  width: 20px;
  height:20px;
  vertical-align: top;
}
.detailstextfieldoption
{
  display: inline-block;
  width: 60%;
  font-size: 18px;
  vertical-align: top;
}
.selectoption
{
  width: 95%;
  border: none;
}


.inptsmall
{
  border: none; width: 76px;
}
.inptbig
{
  border: none; width: 99%;
  
}

.detailsdataoption
{
  display: inline-block;
  width: 80px;
  border: 1px solid #c1d5f7;
  border-radius: 3px;
  background: white;
  height: 25px;
  line-height: 25px;
  text-indent: 2px;
}


.detailsdataoptionlogoutfields
{
  display: inline-block;
  width: 80px;
  border: 1px solid #c1d5f7;
  border-radius: 3px;
  background: white;
  height: 25px;
  line-height: 25px;
  text-indent: 2px;
  margin-left: 57px;
}

.image-preview {
  width: 230px;
  height: 230px;
  background-color: white;
//  margin: 3%;
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  /* justify-content: baseline; */
  text-align: center;
  line-height: 230px;
//  border: 1px solid #c1d5f7;
}

.general-info-wrap{
  background: white;
  //margin: 30px;
  padding: 30px;
}
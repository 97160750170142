@import '../_colors.scss';

.therapist_patient_dashboard_wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: calc(100% - 40px);
  overflow: auto;
}

.dashboard_back_button_wrapper {
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.dashboard_back_button {
  background-image: url(/images/arrow_l_side_bar.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 11px;
  width: 11px;
  padding-right: 5px;
}

.therapist_patient_side_bar_body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.side_bar_buttons {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-height: 250px;
}

.start_remote_session_button_wrapper {
  margin-bottom: 20px;
  padding-right: 2px;
}

.dashboard_button {
  width: 100%;
  border-style: none;
  background-color: $blue_button;
  font-size: 15px;
  color: white;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.therapist_patient_details_wrapper {
  display: flex;
  //flex-direction: column;
  color: $grey-text;
  border-bottom: 1px solid $grey-text;
  padding-bottom: 20px;
  //width: 195px;
}

.progress_precentage {
  color: white;
  text-align: center;
}

.progress_bar {
  color: $green_button;
}

.contact_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 10px 0;
}

.session_timer_wrapper {
  color: white;
  font-size: 35px;
  margin-left: 90px;
}

.contact_opt_wrapper {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding-right: 10px;
  padding-left: 10px;
  color: white;
  cursor: pointer;
  // border-right: 1px solid $grey-text;
  text-align: center;

  //  visibility: hidden;
  &.last {
    border-right: none;
  }
}

.treatment_wrapper {
  display: flex;
  flex-direction: column;
}

.treatment_titel_carousel_wrapper {
  display: flex;
  flex-direction: column;
}

.treatment-plan-carousel-wrapper {
  height: 270px;
}

.treatment_header_wrapper {
  position: relative;
}

.treatment_title_name {
  color: $blue_title;
  height: 40px;
  font-size: 22px;
  text-transform: uppercase;
}

.carosel_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.empty_box_upcoming_appointments_wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  height: calc(100% - 290px);
}

.empty_box_wrapper {
  width: calc(100% - 277px);
  height: 100%;
}

.empty_box_titel_commit {
  color: $blue_text_color;
  height: 40px;
  font-size: 17px;
  text-transform: uppercase;

  &.click {
    cursor: pointer;
  }
}

//** create new appointment modal **//

.accordion_wrapper_open {
  display: inherit;
}

.accordion_wrapper_closed {
  display: none;
}

.create_new_appointment_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 15px;

  &.already-exists-treatment {
    background-color: rgba(242, 242, 242, 0.5);
    border-radius: 10px;
    padding-bottom: 15px;
    padding-top: 0px;
  }
}

.input_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.select-duration-wrapper {
  display: flex;
}

//.select-duration{
//}

.select-duration-buttons-wrapper {
  display: flex;
  flex-direction: column;
}

.select-duration-button {
  margin-left: 10px;
  cursor: pointer;
  background-image: url(/images/Arrow_down.png);
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: contain;

  &.up {
    background-image: url(/images/Arrow_up.png);
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.dialog_input_img {
  height: 40px;
  width: 40px;
  background-size: contain;
  padding-right: 5px;
}

.dialog_input_img_button {
  height: 40px;
  width: 40px;
  background-size: contain;
  padding-right: 5px;
  cursor: pointer;
}

.dialog_location {
  position: relative;
  font-size: 16px;
  width: 256px;
  display: inline-block;
  line-height: 24px;
  background-color: transparent;
  font-family: Roboto, sans-serif;
  transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: auto;
  border: none;
  border-bottom: 1px lightgray solid;
}

.dialog_label {
  position: relative;
  font-size: 16px;
  width: 276px;
  height: 28px;
  margin-right: 350px;
  display: inline-block;
  line-height: 24px;
  background-color: transparent;
  font-family: Roboto, sans-serif;
  transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: none;
  border: none;
}


.dialog_checkbox_image {
  position: relative;
  font-size: 16px;
  width: 20px;
  height: 28px;
  display: inline-block;
  line-height: 24px;
  background-color: transparent;
  font-family: Roboto, sans-serif;
  transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: none;
  border: none;
}

.dialog_checkox {
  width: 20px;
  position: relative;
  margin: 20px auto;

  label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    padding-right: 5px;
    top: 0;
    left: 0;
    background: #fcfff4;
    background: linear-gradient(to bottom #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
    border-radius: 4px;
    box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);

    &:after {
      content: '';
      width: 9px;
      height: 5px;
      position: absolute;
      top: 4px;
      left: 4px;
      border: 3px solid #333;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }

    &:hover::after {
      opacity: 0.5;
    }
  }

  input[type=checkbox] {
    visibility: hidden;

    &:checked+label:after {
      opacity: 1;
    }
  }
}



.dialog_checkbox {
  position: relative;
  font-size: 16px;
  width: 256px;
  height: 28px;
  display: inline-block;
  line-height: 24px;
  background-color: transparent;
  font-family: Roboto, sans-serif;
  transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  cursor: auto;
  border: none;
  border-bottom: 1px lightgray solid;
}


.add_session {
  opacity: 0.5;
  color: #33608C;
  height: 20px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 0px;
  cursor: pointer;
}

// date drop down:
.date_drop_down_header {
  background-color: $my_treatment_header;
  width: 100%;
  overflow: auto;
  margin-top: 10px;
  cursor: pointer;
}

.date_drop_down_title {
  color: $blue_title;
  float: left;
  margin-top: 7px;
  margin-left: 15px;
}

.drop_down_button {
  background-position: center;
  background-repeat: no-repeat;
  height: 43px;
  width: 43px;
  float: right;

  &.arrow_down {
    background-image: url(/images/Arrow_down.png);
  }

  &.arrow_up {
    background-image: url(/images/Arrow_up.png);
  }
}

.day_wrapper {
  height: 43px;
  width: calc(100% - 43px);
  float: left;
  display: flex;
  align-items: center;
}

.inner_drop_down_menu {
  min-width: 150px;
}

.task_planner_copy_and_durations {
  display: flex;
  flex-direction: row;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  justify-content: space-between;

  .underlined {
    text-decoration: underline;
  }

  .date {
    &.to {
      text-decoration: underline;
      padding-left: 7px;
    }

    &.from {
      text-decoration: underline;
      padding-right: 7px;
    }

    &.todayOnly {
      margin-left: -40px;
    }
  }

  .task_planner_copy {
    display: flex;
    flex-direction: row;
  }

  .task_planner_duration {
    display: flex;
    flex-direction: row;
  }

  .task_planner_duration_text {
    padding-right: 5px;
  }
}

.task_planner_add_remove_buttons {
  width: 120px;
  //height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;

  .button {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    background-color: #71B2FD;
    cursor: pointer;

    &.add,
    &.remove {
      background-image: url('/images/arrow.png');
      background-repeat: no-repeat;
      background-size: 13px;
      background-position: center center;
      transform: rotate(270deg);
    }

    &.remove {
      transform: rotate(90deg);
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}

//upcoming appointments 
.upcoming_appointments_wrapper {
  display: flex;
  flex-direction: column;
  //width: 277px;
  height: 100%;

  //margin-left: 20px; // if you change this change the value in TreatmentCalander calcTableDimensions() function!!
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $scroll_bar;
  }
}

.upcoming_appointments_title_name {
  color: $blue_title;
  height: 40px;
  font-size: 17px;
  text-transform: uppercase;
}

.create_new_button {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.5;
  color: $blue_title;
  height: 40px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  //  width: 140px;
  padding-left: 10px;
}

.appointments_item {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.cilckable {
    &:hover {
      cursor: pointer;
    }
  }
}

.appointments_circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5%;

  &.purple {
    background-color: $purple;
  }

  &.green {
    background-color: $green;
  }

  &.blue {
    background-color: $light-blue;
  }
}

.no_upcoming_appointments {
  height: 100px;
}

.no-appointments {
  padding: 20px;
  text-align: center;
  color: $blue_text_color;
}

.patient-session-video-container {
  /*position: absolute;*/
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 20px);
}

.therapist-session-diconnect {
  position: absolute;
  bottom: 0;
  background-color: $green_button;
  font-size: 17px;
  font-weight: bold;
  color: white;
  height: 40px;
  width: 200px;
  margin-top: 20px;
  margin-left: 6px;
  text-transform: uppercase;
  cursor: pointer;
}

/* therapist dail page */

.therapist-dailer-wrapper {
  margin: 0 auto;
  overflow-y: auto;
  margin-top: -65px;
  padding: 0px !important;
  min-width: 500px;
}

.patient-pic-in-dailer {
  //    background-image: url(/images/user_default_profile.png);
  background-size: 120px 120px;
  width: 120px;
  height: 120px;
  background-position: center;
  border-radius: 65px;
  border: 2px solid $light-blue;
  margin: 0 auto;
  background-repeat: no-repeat;
}

.patient-name-wrapper {
  display: flex;
  flex-direction: column;
  color: $blue_text_color;
  margin: 20px 0px;
}

.patient-name {
  font-size: 23px;
  text-transform: capitalize;
  text-align: center;
}

.dailing-status-text {
  font-size: 14px;
  text-align: center;
}

.vertical-dots-dailing {
  background-image: url(/images/dots.png);
  background-position: center;
  background-size: contain;
  height: 91px;
  width: 7px;
  background-repeat: no-repeat;
}

.dailing-drawing {
  background-image: url(/images/video.png);
  background-position: center;
  background-size: contain;
  height: 200px;
  width: 200px;
  margin: 30px 0px;
  background-repeat: no-repeat;
}

.hang-up-button {
  background-image: url(/images/RemoteSessionDisconnect.svg);
  background-position: center;
  background-size: contain;
  height: 60px;
  width: 60px;
  cursor: pointer;
  background-repeat: no-repeat;
  padding-bottom: 10px;

  &.disabled {
    opacity: 0.5;
  }
}

.call-up-icon {
  background-image: url(/images/RemoteSessionCall.svg);
  background-position: center;
  background-size: contain;
  height: 60px;
  width: 60px;
  cursor: pointer;
  background-repeat: no-repeat;
  padding-bottom: 10px;

  &.disabled {
    opacity: 0.5;
  }
}

.call-up-buttons-wrapper {
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.call-up-button-wrapper {
  padding-right: 60px;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.call-up-text {
  font-size: 12px;
  margin-top: 10px;
}

.therapist-pic-in-dailer {
  //background-image: url(/images/user_default_profile.png);
  background-size: 120px 120px;
  width: 120px;
  height: 120px;
  background-position: center;
  border-radius: 65px;
  border: 2px solid $light-blue;
  margin: 0 auto;
  background-repeat: no-repeat;
}

.call-modal-header {
  height: 100px;
  display: flex;
  flex-direction: row;
  background-image: url(/images/modal_header.png);
  background-repeat: no-repeat;
  background-size: 100% 100px;
  align-items: center;
}

.call-modal-body {
  margin: 0 auto;
  overflow-y: auto;
  margin-top: -65px;
  padding: 0px !important;
}

.modal-body {
  margin: 0 auto;
  overflow-y: auto;
  padding: 0px !important;
}

.session-ended-pic-in-dailer {
  background-image: url(/images/session_ended.png);
  background-color: white;
  background-size: 52px 52px;
  width: 100px;
  height: 100px;
  background-position: center;
  border-radius: 50px;
  border: 2px solid $light-blue;
  margin: 0 auto;
  background-repeat: no-repeat;
}

.device-disconnected-pic-in-modal {
  background-image: url(/images/warning.svg);
  background-color: white;
  background-size: 52px 52px;
  width: 100px;
  height: 100px;
  background-position: center;
  border-radius: 50px;
  border: 2px solid $light-blue;
  margin: 0 auto;
  background-repeat: no-repeat;
}

.session-ended-text {
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
  color: red;
  margin: 20px 70px 50px 60px;
}

.device-disconnected-text {
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
  color: red;
  margin: 20px 70px 50px 60px;
}

.whiteText {
  color: white;
  text-align: center;
}
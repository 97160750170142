@import '_colors.scss';
@import '_sizes.scss';

.home {
  background-color: $primary-color;
  border: 1px solid black;
  margin: auto;
  width: $main-view-width;
}

.bottom_nav_button {
  background-color: #3EC880 !important;
  color: white !important;
  min-width: 10% !important;
}

.bottom_nav_button.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.bottom_nav_style {
  height: 35px;
  right: 0;
  width: 100%;
  bottom: 15px;
  display: flex;
  position: absolute;
  padding-left: 255px;
  padding-right: 15px;
  justify-content: space-between;
}
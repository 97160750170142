.my-profile-dash {
  height: 100%;

  .my-profile-dashboard {
    width: auto;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    overflow-y: auto;
    height: calc(100% - 100px);

    .my-profile-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .my-profile-title {
        font-size: 20px;
        color: #33608C;
        text-transform: uppercase;
      }

      .general-info {
        background-color: white;

        .image-container {
          // width: 15%;
          display: inherit;
          flex-direction: column;
          padding: 45px 50px;

          .image-buttons {
            height: auto;
            width: 230px;
            cursor: pointer;
            color: white;
            display: flex;
            user-select: none;

            .button {
              width: 115px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              background-color: #71B2FD;

              .seperator {
                position: relative;
                background-color: white;
                width: 1px;
                height: 70%;
                top: 4px;
              }

              .image-preview {
                width: 230px;
                height: 230px;
                background-color: #2E4460;
                //        margin: 0 auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;


              }
            }
          }
        }

        .content {
          width: 100%;
          height: auto;
          padding: 25px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 60px;
            line-height: 30px;

            .name {
              color: #33608c;
              font-size: 17px;
              text-transform: capitalize;
            }

            .email {
              color: #9eb1c6;
              font-size: 17px;
            }

            .role {
              color: #9eb1c6;
              font-size: 17px;
            }
          }

          .image-buttons {
            width: 285px;
            height: auto;
            cursor: pointer;
            color: white;
            display: flex;
            user-select: none;

            .button {
              width: 160px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              margin-right: 25px;
              background-color: #71B2FD;
            }
          }
        }
      }

      .user-info {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 370px;
        background-color: white;
        justify-content: space-around;

        .MuiGrid-root {
          width: 20%;
          display: inherit;
          flex-direction: column;
          padding: 25px 50px 25px 0;
          justify-content: space-between;

          .input-wrapper {
            width: 100%;
            padding-bottom: 19px;
            position: relative;

            & div:first-child {
              //       height: 25px;

              & div:first-child {
                height: 25px !important;
              }
            }

            hr {
              display: none;
            }

            input,
            .datepickercomp {
              width: 100%;
              height: 25px;
              outline: none;
              background-color: transparent;
              font-size: 17px;
              color: #01305f !important;
              border: 1px solid #a6a6a6;
              border-radius: 3px;
              padding: 3px 7px;
            }

            select {
              width: 108%;
              height: 34px;
              outline: none;
              background-color: transparent;
              font-size: 17px;
              color: #01305f !important;
              border: 1px solid #a6a6a6;
              border-radius: 3px;
              padding: 3px 7px;
            }

            input:focus {
              box-shadow: 0 0 5px rgba(81, 203, 238, 1);
              border: 1px solid rgba(81, 203, 238, 1);
            }

            select {
              text-transform: capitalize;

            }

            .name {
              text-transform: capitalize;
              width: 100%;
              color: #33608c;
              font-size: 16px;
              font-weight: 600;
              padding-left: 2px;
              padding-top: 10px;

              &.mendatory {
                color: red;
              }

            }
          }




          .gender-input {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            font-size: 20px;
            text-transform: capitalize;
            margin: 10px 0px 3px -10px;

            input[type="radio"]:not(old) {
              opacity: 0;

              &:checked~.check::before {
                background: #71B2FD;
              }
            }

            label {
              display: flex;
              width: 115px;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              color: #33608c;
              font-size: 17px;

              .check {
                display: flex;
                position: relative;
                border: 2px solid #AAAAAA;
                border-radius: 100%;
                height: 20px;
                width: 20px;
                z-index: 5;
                transition: border .25s linear;
                -webkit-transition: border .25s linear;
                justify-content: center;

                &::before {
                  display: block;
                  position: relative;
                  content: '';
                  border-radius: 100%;
                  height: 12px;
                  width: 12px;
                  margin: auto;
                  transition: background 0.25s linear;
                  -webkit-transition: background 0.25s linear;
                }
              }
            }
          }

          textarea {
            height: 220px;
            width: 104%;
            resize: none;
            background-color: #f6faff;
            border: 0;
            font-size: 17px;
            color: #9eb1c6;

            &::-webkit-input-placeholder {
              font-family: "proxima-nova", sans-serif;
            }
          }
        }
      }
    }
  }

  .footer {
    background: #ffffff;
    position: fixed;
    width: calc(100% - 277px);
    bottom: 0px;
    height: 100px;

    .buttons {
      position: fixed;
      right: 2.5%;
      display: flex;
      margin-top: 25px;

      .button {
        height: 49px;
        width: 200px;
        background-color: #3EC880;
        margin-left: 25px;
        color: #FFFFFF;
        font-size: 17px;
        text-transform: uppercase;
        text-align: center;
        line-height: 49px;
        cursor: pointer;

        &.cancel {
          background-color: transparent;
          border: 2px solid #3EC880;
          color: #3EC880;
          height: 47px;
        }

        &.disabled {
          cursor: default;
          opacity: 0.5;
        }
      }
    }
  }
}

.teamviewer_box {
  position: relative;
  padding-left: 68px;
  padding-bottom: 35px;
}

.teamviewer_box_style {
  position: absolute;
  top: 22.5px;
  left: 88px;
  display: block;
  cursor: pointer;
  color: White;
  font-family: Arial;
  font-size: 13px;
  line-height: 1.2em;
  font-weight: bold;
  text-align: center;
  width: 174px;
  padding-left: 35px;
}

.my_setting_box {
  background-color: white;
}

.download_button {
  width: 120%;
  color: white;
  text-decoration: none;
}


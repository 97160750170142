@import '../_colors.scss';

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}


.finance-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .finance-contents {
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;

    .invoices-table {
      height: 40%;
    }
  }

  .hidden-print-area {
    position: absolute;
    top: 100000000px;
    height: 100%;
    width: 100%;
  }

  .footer {
    clear: both;
    background: #f1f7ff;
    text-align: center;
    line-height: normal;
    height: 100px;
    width: inherit;
    display: flex;
    flex-direction: row;
    padding: 0px 120px;
    justify-content: space-between;
    position: absolute;
    right: 0;
    left: 0;

    .buttons {
      display: flex;
      margin-top: 25px;

      .button {
        height: 40px;
        width: 200px;
        background-color: #3EC880;
        margin-left: 25px;
        color: #FFFFFF;
        font-size: 15px;
        text-transform: uppercase;
        text-align: center;
        line-height: 39px;
        cursor: pointer;

        &.disabled {
          cursor: default;
          opacity: 0.5;
        }

        &.none {
          display: none;
        }
      }
    }
  }
}

.discount-wrapper {
  display: flex;
  flex-direction: row;

  .discount {
    width: 40px;
  }
}

body {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  overflow: hidden;
}

.inputchk {
  width: 25px;
  height: 20px;
}

.spanchk {

  font-size: 17px;
  font-family: "proxima-nova", sans-serif;

}

.adminoption {
  font-size: 17px;
  font-family: "proxima-nova", sans-serif;
}

.fix {

  height: 245px !important;

}

.general-info-title-sites {
  padding: 10px 0 10px 0 !important;

}

.tables-wrapper-sites {
  height: 250px !important;
}

.table-container-sites {
  margin-left: 0px !important;
}

.headersiteaccount {

  text-align: left;
  font-size: 18px;
  font-family: "proxima-nova", sans-serif;
  font-weight: bold;
}


.sectioncat {

  text-align: left;
  float: left;
  width: 300px;
  height: 50px;
  text-indent: 3%;
  line-height: 50px;
  vertical-align: middle;
  font-size: 16px;


}

.cathead {
  text-align: center;
  float: left;
  width: 80px;
  height: 40px;
  border-radius: 3px;
  /* border: 1px solid silver; */
  margin-left: 20px;
  border-radius: 10px;
  line-height: 40px;
  font-size: 16px;
}

.sectioncatdata {

  float: left;
  width: 300px;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  text-align: center;
}

.sectioncatdatabox {

  text-align: center;
  float: left;
  width: 70px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #565d69;
  margin-left: 20px;
  border-radius: 10px;
  line-height: 40px;
  background: #c1d5f7;

}

.title {
  font-size: 22px;
  color: #5179A5;
  text-transform: uppercase;
}

.total {
  color: #71b2fd;
  font-size: 22px;
  text-transform: uppercase;
}

.devices-modal-wrapper {
  width: 1300px;
}

.devicesModalWrapper {
  max-width: unset;

  .title {
    height: 22px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 16px;
    background-size: 15px 15px;
    background-position-y: 4px;
    color: #99AFC5;
    user-select: none;
    font-weight: bold;
  }
}

.table_size{
  height: 600px;
}

.devices-wrapper{
  height: 100%;
  overflow: hidden;
}

.devicesHeaderWrapper {
  display: flex;
  justify-content: space-between;

  .sideWrapper {
    display: flex;
    justify-content: space-around;
    height: 70px;
    align-items: center;
  }

  .button {
    height: 22px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    // padding-left: 25px;
    font-size: 16px;
    background-size: 15px 15px;
    background-position-y: 4px;
    color: #99AFC5;
    user-select: none;
    font-weight: bold;
    margin-left: 45px;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .button:last-child{
    padding-right: 20px;
  }
}

.registeredonline {
  float: left;
}

.clear {
  clear: both;
  margin-top: 10px;
}

.detailstextfield {
  display: inline-block;
  width: 50%;
  font-size: 18px;
  vertical-align: top;

}

.bodytbl::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.bodytbl::-webkit-scrollbar {
  width: 7px;
  background-color: transparent;
}

.bodytbl::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #5179a5;
  cursor: pointer;
}

.checkboxoption {

  display: inline-block;
  width: 400px;
  height: 20px;
  vertical-align: top;
}

.actionbtns {
  margin-left: 60%;
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
}

div.roweven:nth-child(odd) {
  background: #F7FAFF
}


.next-step-button-superadmin {
  height: 50px;
  width: 250px;
  background-color: #3EC880;
  margin-left: 25px;
  color: #FFFFFF;
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
  line-height: 50px;
  cursor: pointer;

  &.secondary {
    color: white;
    background-color: $green;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.refresh {
    background-color: $light-blue;
  }
}

.superadmin-localization-toggle-text {
  font-size: 18px;
  vertical-align: top;
  // padding-left: 25px;
  margin-right: -100px;
}

.superadmin-localization-toggle {
  position: relative;
  margin-right: 30px;
}

.localization-total {
  margin-top: -20px;
  margin-bottom: 5px;
  font-size: 22px;
  color: #5179A5;
  text-transform: uppercase;
  white-space: nowrap;
  padding-right: 10px;
}


.rightside {
  margin: 30px 30px;
}

.buttonoption.cancel {
  margin-top: 2px;
  background-color: transparent;
  border: 2px solid #3EC880;
  color: #3EC880;
  height: 49px;
}



.buttonoption.disabled {
  cursor: default;
  opacity: 0.5;
}

.header div {
  width: 100%;
  text-align: left;
  //  padding-left: 20px;
  cursor: pointer;
  vertical-align: middle;
  line-height: 40px;
}

.data-row {

  width: 33%;
  font-size: 17px;
  text-transform: capitalize;
  line-height: 40px;

  color: #9eb1c6 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // padding-right: 23px;
}

.pad_left {
  padding-left: 20px;
}

.bodytbl {
  height: calc(100% - 140px);
  width: 100%;
  background-color: white;
  overflow: auto;
}

.bodytbl.modal{
    height: 400px !important;
}

.devices-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  &.even {
    background-color: white;
  }

  &.odd {
    background-color: rgb(229, 238, 255);
  }

  &.selected {
    font-weight: bold;
  }

  .data-row {
    font-size: 15.5px;
  }
}

.header {
  width: 100%;
  height: 40px;
  background-color: #5179a5;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  text-transform: capitalize;
}

.buttonoption {
  height: 49px;
  width: 124px;
  background-color: #3EC880;
  margin-left: 25px;
  color: #FFFFFF;
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
  line-height: 49px;
  border: none;
  cursor: pointer;
}

.checkboxoptionbtnspn {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: top;
}

.selectoption {
  width: 95%;
  border: none;
}

.inptsmall {
  border: none;
  width: 76px;
}

.inptbig {
  border: none;
  width: 99%;

}

.detailsdataoption {
  display: inline-block;
  width: 80px;
  border: 1px solid #c1d5f7;
  border-radius: 3px;
  background: white;
  height: 25px;
  line-height: 25px;
  text-indent: 2px;
}

.detailstext {
  margin-bottom: 10px;
  margin-top: 10px;
}

.detailsdata {
  display: inline-block;
  width: 40%;
  border: 1px solid #c1d5f7;
  border-radius: 3px;
  background: white;
  height: 25px;
  line-height: 25px;
  text-indent: 2px;
  overflow: hidden;
}

.image-preview {
  width: 230px;
  height: 230px;
  background-color: white;
  //  margin: 3%;
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  /* justify-content: baseline; */
  text-align: center;
  line-height: 230px;
  //  border: 1px solid #c1d5f7;
}

.buttons_row{
  display: flex;
}

.input_name{
  width: 500px;
}

.input_length{
  margin-left: 10px !important;
  width: 120px;
}
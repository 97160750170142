.myPatientData-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.evaluationTable-stats {
  flex-grow: 1;
  //   margin-right: 30px;
  max-height: 600px;
  margin-top: 20px;
}

.myPatientTableData-wrapper {
  height: 100%;
  flex-grow: 1;
  //   padding: 0 33px 0 33px;
  max-height: 350px;
}

.myCompletedInsight-wrapper {
  height: 100%;

  .table-wrapper {
    .table-header {
      .table-header {
        cursor: default;

        .i0 {
          width: 18%;
        }

        .i1 {
          width: 37%;
        }

        .i2 {
          width: 15%;
        }

        .i3 {
          width: 15%;
        }

        .i4 {
          width: 15%;
        }

        .small {
          width: 60%;
        }
      }
    }

    .table-content {
      // height: 200px;

      .row {
        cursor: default;

        .cell {}

        .small {
          width: 60%;
        }
      }
    }
  }
}

.my_completed_tasks_commit {
  // margin-left: 900px;
  // margin-top: 540px;
  width: -webkit-fill-available;
  height: 90px;
  margin-top: -50px;
  background-color: #fff;
  border: 3px solid #e5eeff;
  resize: none;
  padding: 4px;
}

.my_completed_tasks_commit_buttons {
  display: flex;
  padding-bottom: 40px;
  justify-content: space-between;
}

.my_completed_box_commit {
  padding-top: 20px;
}

.general-info-title {
  font-size: 20px;
  color: #33608c;
  text-transform: uppercase;
  padding: 20px 0 20px 0;
}

.details-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.details-price-list-wrapper {
  margin-left: 40px;
  margin-right: 40px;
}

.pricelist {
  width: inherit;
  background-color: white;
  padding: 25px 50px 25px 45px;

  .pricelist-header {
    width: inherit;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: 20px;

    .pricelist-header-cell {
      width: 100%;
      text-align: left;
      padding-left: 10px;
      text-transform: capitalize;
      background-color: #5179a5;
    }
  }

  .pricelist-body {
    width: inherit;
    max-height: 300px;
    overflow: auto;
    overflow-x: hidden;
    color: #99afc5;

    .pricelist-row {
      width: 100%;
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: default;

      .pricelist-cell {
        width: 100%;
        padding-left: 10px;
        text-align: left;
        text-transform: capitalize;
        font-size: 17px;
      }

      .pricelist-cell-edit {
        width: 100px;
        text-align: center;
        border: none;
        background-color: antiquewhite;
        height: 25px;
        font-size: 17px;
      }
    }
  }
}

.details-translation-wrapper {
  margin-left: 40px;
}

.translation {
  width: inherit;
  height: 100%;

  .translation-header {
    width: inherit;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: 20px;

    .translation-header-cell {
      width: 100%;
      text-align: left;
      padding-left: 10px;
      //text-transform: capitalize;
      background-color: #5179a5;
      display: flex;
    }

    .translation-header-languges {
      padding-right: 10px;
    }
  }

  .translation-body {
    width: inherit;
    max-height: 690px;
    overflow: auto;
    overflow-x: hidden;
    color: #99afc5;

    .translation-row {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: default;

      .translation-cell {
        width: 100%;
        padding-left: 10px;
        text-align: left;
        //    text-transform: capitalize;
        font-size: 17px;
        overflow: auto;
      }

      .translation-cell-edit {
        width: 900px;
        text-align: left;
        border: none;
        background-color: antiquewhite;
        height: 25px;
        font-size: 17px;

        &.error {
          background-color: yellow;
          color: red;
        }
      }
    }
  }
}

.image-selector {
  width: 215px;
  margin: 35px auto;

  .image-container {
    width: 230px;
    // height: 230px;
    background-color: #f6faff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .image-buttons {
    width: 230px;
    height: 30px;
    background-color: #71b2fd;
    cursor: pointer;
    color: white;
    display: flex;
    user-select: none;
    text-transform: capitalize;

    .button {
      width: 130px;
      height: inherit;
      text-align: center;
      line-height: 30px;

      &.disabled {
        cursor: not-allowed;
      }
    }

    .seperator {
      position: relative;
      background-color: white;
      width: 1px;
      height: 70%;
      top: 4px;
    }
  }
}

.usersDashboard-wrapper {
  height: calc(100% - 10px);

  .table-wrapper {}

  .custom_input {
    width: 90%;
    margin: 15px 0 9px 0;
  }

  .add-edit-dash {
    height: 100%;

    .add-edit-dash-disabler {
      width: 100%;
      height: 100%;
      z-index: 100000;
      position: absolute;
    }

    .add-edit-dashboard {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 100px);
      overflow-y: auto;

      .general-info-wrap {
        //overflow-y: auto;
        justify-content: space-around;
      }

      .general-info {
        background-color: white;
      }

      .MuiGrid-root {
        .password-cube {
          cursor: pointer;
          position: absolute;
          right: 43px;
          top: 28px;
          height: 35px;
          width: 35px;

          &.disabled {
            cursor: not-allowed;
          }
        }

        .input-wrapper {
          width: 90%;
          position: relative;

          & div:first-child {
            //    height: 25px;

            & div:first-child {
              height: 25px !important;
            }
          }

          hr {
            display: none;
          }

          input,
          .datepickercomp {
            width: 100%;
            height: 25px;
            outline: none;
            background-color: transparent;
            font-size: 17px;
            color: #01305f !important;
            border: 1px solid #a6a6a6;
            border-radius: 3px;
            padding: 3px 7px;
          }

          input:focus {
            box-shadow: 0 0 5px rgba(81, 203, 238, 1);
            border: 1px solid rgba(81, 203, 238, 1);
          }

          select {
            text-transform: capitalize;
            width: 105%;
            height: 34px;
            outline: none;
            background-color: transparent;
            font-size: 17px;
            color: #01305f !important;
            border: 1px solid #a6a6a6;
            border-radius: 3px;
            padding: 3px 7px;
          }

          .input-title-wrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .comment {
              font-size: 15px;
              color: orange;
              font-weight: 400;
              white-space: nowrap;
              padding-bottom: 5px;
              margin-right: -14px;
            }
          }

          .name {
            text-transform: capitalize;
            width: 100%;
            color: #33608c;
            font-size: 16px;
            font-weight: 600;
            padding-left: 2px;
            padding-top: 10px;
            height: 25px;

            &.mendatory {
              color: red;
            }
          }
        }

        .gender-input {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          font-size: 20px;
          text-transform: capitalize;
          margin: 10px 0px 3px -10px;

          input[type="radio"]:not(old) {
            opacity: 0;

            &:checked~.check::before {
              background: #71b2fd;
            }
          }

          label {
            display: flex;
            width: 115px;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #33608c;
            font-size: 17px;

            .check {
              display: flex;
              position: relative;
              border: 2px solid #aaaaaa;
              border-radius: 100%;
              height: 20px;
              width: 20px;
              z-index: 5;
              transition: border 0.25s linear;
              -webkit-transition: border 0.25s linear;
              justify-content: center;

              &::before {
                display: block;
                position: relative;
                content: "";
                border-radius: 100%;
                height: 12px;
                width: 12px;
                margin: auto;
                transition: background 0.25s linear;
                -webkit-transition: background 0.25s linear;
              }
            }
          }
        }

        textarea {
          height: 89px;
          width: 100%;
          resize: none;
          border: 0;
          font-size: 17px;
          color: #013084;
          border: 1px solid #a6a6a6;
          border-radius: 3px;
          padding: 3px 7px;
        }

        textarea:focus {
          box-shadow: 0 0 5px rgba(81, 203, 238, 1);
          border: 1px solid rgba(81, 203, 238, 1);
        }

        &::-webkit-input-placeholder {
          font-family: "proxima-nova", sans-serif;
        }
      }


      .modify-patient-patient-specific-wrapper {
        display: flex;
        flex-direction: row;
      }

      .config-wrapper {
        width: 100%;
      }


      .table-container {
        width: 100%;

        .title-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .title {
            font-size: 20px;
            color: #33608c;
            text-transform: uppercase;
            padding: 10px 0 10px 0;
            //width: 100%;
          }

          .button {
            font-size: 16px;
            color: #99afc5;
            text-transform: uppercase;
            cursor: pointer;
            background-size: 13px 13px;
            background-repeat: no-repeat;
            // width: 65px;
            text-align: right;
            background-position: 0px;
          }

          .edit {
            background-image: url("/images/edit.svg");
            padding-left: 20px;
          }

          .add {
            background-image: url("/images/add.svg");
            padding-left: 20px;
          }
        }

        .table {
          width: 100%;
          //   height: 187px;
          //background-color: black;

          .header {
            width: 100%;
            height: 40px;
            background-color: #5179a5;
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &>div {
              width: 33%;
              height: 100%;
              font-size: 17px;
              color: white;
              text-transform: capitalize;
              line-height: 40px;
              padding-left: 25px;
            }
          }

          .body {
            width: 100%;
            min-height: 100px;
            background-color: white;
            overflow: auto;

            .row {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              position: relative;

              &.even {
                background-color: #f7faff;
              }

              .data-row {
                width: 33%;
                font-size: 17px;
                text-transform: capitalize;
                line-height: 40px;
                padding-left: 25px;
                color: #9eb1c6 !important;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                padding-right: 23px;
              }

              .delete-row {
                width: 20px;
                height: 20px;
                background-color: #5179a5;
                position: absolute;
                right: 35px;
                top: 10px;
                border-radius: 100%;
                color: white;
                font-weight: 900;
                text-align: center;
                line-height: 20px;
                cursor: pointer;
                display: none;

                &.show {
                  display: block;
                }
              }
            }

            &::-webkit-scrollbar {
              width: 10px;
              background-color: transparent;
            }

            &::-webkit-scrollbar-track {
              background-color: transparent;
              border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: #5179a5;
              cursor: pointer;
            }
          }
        }
      }
    }

    .footer {
      background: #ffffff;
      position: fixed;
      width: calc(100% - 277px);
      bottom: 0px;
      height: 100px;

      .buttons {
        position: fixed;
        right: 2.5%;
        display: flex;
        margin-top: 25px;

        .button:hover {
          background-color: #4aad79;
        }

        .button {
          height: 49px;
          width: 200px;
          background-color: #3ec880;
          font-size: 17px;
          margin: 0 25px;
          color: white;

          &.cancel {
            background-color: transparent;
            border: 2px solid #3ec880;
            color: #3ec880;
          }

          &.cancel:hover {
            border: 2px solid #3ec880;
            color: #3ec880;
            background-color: #efefef;
          }

          &.disabled {
            cursor: default;
            opacity: 0.5;
          }

          &.none {
            display: none;
          }
        }
      }
    }
  }
}

.config-sect {
  padding: 1% 2% 1% 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #3c6fa4;

  .config-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1% 0;

    .config-line-text {
      display: inline-block;
      width: 30%;
      min-width: 350px;
      text-indent: 3.5%;
      font-size: 18px;
      vertical-align: top;
      text-transform: capitalize;
    }

    .config-line-field {
      position: relative;

      .config-line-field-disabler {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1000000;
        cursor: not-allowed;
      }

      .config-drop-down-wrapper {
        position: relative;
        display: inline-block;
        width: 80px;
        border-radius: 3px;
        background: white;
        height: 30px;
        line-height: 30px;
        text-indent: 2px;
        background-color: antiquewhite;

        &.disabled {
          background-color: lightgrey;
        }

        .config-drop-down-disabler {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: none;
          background-color: rgba(255, 255, 255, 0);
          z-index: 2;
          cursor: not-allowed;
        }

        .config-drop-down {
          width: 95%;
          border: none;
          background-color: antiquewhite;
          cursor: pointer;
          z-index: 1;

          &.disabled {
            background-color: lightgrey;
          }
        }
      }

      .config-select-wrapper {
        padding-left: 26px;

        .config-select-disabled {
          color: grey;
        }
      }
    }
  }
}

.positionRelative {
  position: relative;
}
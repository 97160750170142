$primary-color: #ccc;
$blue_title: #33608C;
$scroll_bar: #91abc4;
$green: #3fc880;
$light-blue: #6da9ff;
$grey-text: #798c9e;

/* login page colors  */
$screen_background: #669AFF;
$header_background: #273F5B;
$text_color: #3F6EA3;

// patient dashboard page colors :
$blue_text_color: #33608C;
$scroll_bar: #91abc4;
$side_bar_color: #273f5b;
$my_treatment_header: #e3f0ff;
$my_treatment_header_current_day: #5079a5;
// common
$green_button: #3EC880;
$green_button_disabled: #a7d8be;
$scroll_bar: #91abc4;
$purple: #9eb6fe;
$white: white;
$blue_button:#3e83c8;

$light-grey: #fcfdff;
$gray: #ccd7e0;

$video-contact: #5079a5;
$mail-contact: #6094d0;
$chat-contact: #70b2fc;
$grey-border-color: #cdd9e7;

$gouge-blue: #71b0fb;
$setting-box-title-background: #e3effd;

$red: #dd5a56;
$light-gray: #dee7f0;
.common-table-wrapper {
  width: inherit;
  height: 100%;
  
  .search-and-title {
    width: inherit;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-self: flex-start;
      height: inherit;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 22px;
        color: #5179A5;
        text-transform: uppercase;
        white-space: nowrap;
        padding-right: 10px;
      }

      .total {
        color: #71b2fd;
      }

      .search-field {
        display: flex;
        input {
          width: 242px;
          height: 22px;
          padding-left: 10px;
          color: #99AFC5;
        }
        .icon {
          background-image: url('/image/search.svg');
          background-repeat: no-repeat;
          width: 15px;
          height: 15px;
          position: relative;
          right: 25px;
          top: 4px;
        }
      }
    }
  }

  .common-table-header {
      width: inherit;
      height: 50px;
      background-color: #5179A5;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      font-size: 20px;
    //  text-transform: capitalize;
      color: white;

      .head {
        width: 100%;
        text-align: left;
        padding-left: 10px;
        cursor: pointer;
        text-transform: capitalize;
        min-width: 50px;
        flex-grow: 1;
      }
    }


  .common-table-content {
    width: inherit;
    height: calc(100% - 110px);
    overflow: auto;
    overflow-x: hidden;

    &.limitheight{
      height: 100%;
      max-height: 350px;
    }
      
      .row {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        color: #99AFC5;
        cursor: pointer;

        &.selected {
          font-weight: bold;
        }

        &.even {
          background-color: white;
        }

        &.odd {
          background-color: rgb(229, 238, 255);
        }

        .cell {
          width: 100%;
          padding-left: 10px;
          text-align: left;
          text-transform: capitalize;
          font-size: 17px;
          white-space: nowrap;
          min-width: 50px;
          flex-grow: 1;
        }
      }

      &::-webkit-scrollbar {
          width: 10px;
          background-color: transparent;
      }

      &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #5179a5;
      }
    }
  }
  
.avatar-icon{
  cursor: pointer;
}
.table-wrapper {
  width: inherit;
  height: 100%;

  .table-header {
    width: inherit;
    //margin-top: 18px;
    //height: 110px;

    .search-header {
      width: inherit;
      //height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .left {
        display: flex;
        height: inherit;
        align-items: center;
        justify-content: space-between;


        height: 22px;
        text-transform: uppercase;
        font-size: 16px;
        color: #99AFC5;
        user-select: none;

        .title {
          font-size: 22px;
          color: #5179A5;
          text-transform: uppercase;
          white-space: nowrap;
          padding-right: 10px;
        }

        .total {
          color: #71b2fd;
        }

        .title-filter {
          padding-left: 5%;
          display: flex;
          height: 28px;
          align-items: center;
          white-space: nowrap;

          .MuiOutlinedInput-root {
            height: 34px;
          }

          .MuiSelect-selectMenu {
            color: #5178a5;
          }
        }

        .nowrap {
          white-space: nowrap;
        }

        .columns_filter_list {
          min-width: 200px;
        }

        .search-field {
          display: flex;
          flex-direction: row-reverse;

          input {
            width: 242px;
            height: 22px;
            padding-left: 10px;
            color: #99AFC5;
          }

          .icon {
            position: absolute;
            margin-top: 2px;
          }
        }
      }

      .right {
        display: flex;
        align-self: flex-start;
        height: inherit;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;
      }

      .MuiButton-outlinedPrimary {
        color: #5178a5;
      }



      button {
        margin-left: 5px;

        .MuiSvgIcon-root {
          padding-right: 5px;
        }
      }

      .button {
        height: 22px;
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 25px;
        font-size: 16px;
        background-size: 15px 15px;
        background-position-y: 4px;
        color: #99AFC5;
        user-select: none;
        font-weight: bold;
        margin: 0 5px;

        &.all {
          background-image: url('/images/all_patients.svg');
          background-repeat: no-repeat;
          white-space: nowrap;
          background-size: 18px;
        }

        &.allTherapists {
          background-image: url('/images/all_patients.svg');
          background-repeat: no-repeat;
          background-size: 18px;
        }

        &.select {
          background-image: url('/images/select.png');
          background-repeat: no-repeat;
          background-size: 18px;

          &.notAllowed {
            cursor: not-allowed;
            font-weight: normal;
          }
        }

        &.delete {
          background-image: url('/images/delete.svg');
          background-repeat: no-repeat;
          background-size: 18px;

          &.notAllowed {
            cursor: not-allowed;
            font-weight: normal;
          }
        }

        &.edit {
          background-image: url('/images/edit.svg');
          background-repeat: no-repeat;
          background-size: 18px;

          &.notAllowed {
            cursor: not-allowed;
            font-weight: normal;
          }
        }

        &.add {
          background-image: url('/images/add.svg');
          background-repeat: no-repeat;
          white-space: nowrap;
          background-size: 18px;
        }
      }
    }

    .table-header {
      width: inherit;
      height: 50px;
      background-color: #5179A5;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      font-size: 20px;
      //  text-transform: capitalize;
      color: white;

      .head {
        width: 100%;
        text-align: left;
        padding-left: 10px;
        cursor: pointer;
        text-transform: capitalize;
        min-width: 50px;
        flex-grow: 1;
      }

      .i0 {
        width: 18%
      }

      .i1 {
        width: 37%
      }

      .i2 {
        width: 15%
      }

      .i3 {
        width: 15%
      }

      .i4 {
        width: 15%
      }
    }
  }


  .table-content {
    width: inherit;
    height: calc(100% - 130px);
    overflow: auto;
    overflow-x: hidden;

    &.limitheight {
      height: 100%;
      max-height: 350px;
    }

    .row {
      width: 100%;
      min-height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      color: #99AFC5;
      cursor: pointer;

      &.selected {
        font-weight: bold;
      }

      &.even {
        background-color: white;
      }

      &.odd {
        background-color: rgb(229, 238, 255);
      }

      .cell {
        width: 100%;
        padding-left: 10px;
        text-align: left;
        text-transform: capitalize;
        font-size: 17px;
        white-space: nowrap;
        min-width: 50px;
        flex-grow: 1;
        text-overflow: clip;
        overflow: hidden;
        white-space: break-spaces;
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #5179a5;
    }
  }
}
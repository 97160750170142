.invoiceWrapper {
  width: inherit;
  margin-top: 50px;
  height: 60%;

  .invoice-header {
    width: inherit;
    height: 50px;
    background-color: #5179A5;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    text-transform: capitalize;
    color: white;

    .invoice-head {
      width: 100%;
      text-align: left;
      padding-left: 10px;
      cursor: default;
      text-transform: capitalize;
      min-width: 50px;
      flex-grow: 1;
    }
  }


  .invoice-content {
    width: inherit;
    height: calc(100% - 50px);
    overflow: auto;
    overflow-x: hidden;

    .invoice-row {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      color: black;
      cursor: pointer;
      background-color: white;
      border-bottom: lightgray solid 1px;

      .invoice-cell {
        width: 100%;
        padding-left: 10px;
        text-align: left;
        text-transform: capitalize;
        font-size: 17px;
        white-space: nowrap;
        min-width: 50px;
        flex-grow: 1;

        &.invoice-total {
          font-size: 19px;

          &.invoice-desc {
            text-align: right;
            padding-right: 10px;
          }
        }

        .invoice-comment {
          font-size: 13px;
          color: red;
          padding-left: 15px;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #5179a5;
    }
  }
}
@import '../_colors.scss';

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

body {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
}

.general-info-title-sites {
  padding: 10px 0 10px 0 !important;

}

.site-configurations {
  margin-bottom: 100px
}

.rule {
  color: #33608c;
  font-size: 16px;
  font-weight: 600;
  margin: 18px 0;
}

.control {
  display: flex;
  flex-direction: row;
  padding-bottom: 14px;
}

.toggle {

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}


.minus_button {
  background-image: url(/images/add.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 30px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
}

.text {
  color: #33608c;
  font-size: 29px;
  font-weight: 600;
  padding: 0 10px;
}

.plus_button {
  background-image: url(/images/add.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 30px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
}


.tables-wrapper-sites {
  height: 250px !important;
}

.table-container-sites {
  margin-left: 0px !important;
}

.sectioncat {

  text-align: left;
  float: left;
  width: 300px;
  height: 50px;
  text-indent: 3%;
  line-height: 50px;
  vertical-align: middle;
  font-size: 16px;


}

.cathead {
  text-align: center;
  float: left;
  width: 80px;
  height: 40px;
  border-radius: 3px;
  /* border: 1px solid silver; */
  margin-left: 20px;
  border-radius: 10px;
  line-height: 40px;
  font-size: 16px;
}

.sectioncatdata {

  float: left;
  width: 300px;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  text-align: center;
}

.sectioncatdatabox {

  text-align: center;
  float: left;
  width: 70px;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #565d69;
  margin-left: 20px;
  border-radius: 10px;
  line-height: 40px;
  background: #c1d5f7;

}

.title {
  font-size: 22px;
  color: #5179A5;
  text-transform: uppercase;
}

.total {
  color: #71b2fd;
  font-size: 22px;
  text-transform: uppercase;
}

.registeredonline {
  float: left;
}

.clear {
  clear: both;
  margin-top: 10px;
}

.bodytbl::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.bodytbl::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

.bodytbl::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #5179a5;
  cursor: pointer;
}

.checkboxoption {

  display: inline-block;
  width: 400px;
  height: 20px;
  vertical-align: top;
}

.actionbtns {
  margin-left: 60%;
  width: 100%;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
}

div.roweven:nth-child(odd) {
  background: #F7FAFF
}

.buttonoption.cancel {
  margin-top: 2px;
  background-color: transparent;
  border: 2px solid #3EC880;
  color: #3EC880;
  height: 49px;
}



.buttonoption.disabled {
  cursor: default;
  opacity: 0.5;
}

// .header div{
//   width: 100%;
//   text-align: left;
//   padding-left: 10px;
//   cursor: pointer;
//   vertical-align: middle;
//   line-height: 40px;
// }

// .data-row
// {

//   width: 33%;
//   font-size: 17px;
//   text-transform: capitalize;
//   line-height: 40px;
//   padding-left: 25px;
//   color: #9eb1c6 !important;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
//   padding-right: 23px;


// }
.roweven {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.header {
  width: 100%;
  height: 40px;
  background-color: #5179a5;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
}

.buttonoption {
  height: 49px;
  width: 124px;
  background-color: #3EC880;
  margin-left: 25px;
  color: #FFFFFF;
  font-size: 17px;
  text-transform: uppercase;
  text-align: center;
  line-height: 49px;
  border: none;
  cursor: pointer;
}

.checkboxoptionbtnspn {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: top;
}

.selectoption {
  width: 95%;
  border: none;
}

.image-preview-details {
  max-width: 100%;
  height: auto;
  background-color: #708caf;
  //  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
}

.inptsmall {
  border: none;
  width: 76px;
}

.inptbig {
  border: none;
  width: 99%;

}

.detailsdataoption {
  display: inline-block;
  width: 80px;
  border: 1px solid #c1d5f7;
  border-radius: 3px;
  background: white;
  height: 25px;
  line-height: 25px;
  text-indent: 2px;
}

.image-preview {
  width: 230px;
  height: 230px;
  background-color: white;
  flex-direction: row;
  align-items: center;
  text-align: center;
  line-height: 230px;
}

.sideWrapper {
  .total {
    margin-left: 3px;
  }
  .search-field {
    input {
      margin: 0px 15px;
      color: #99AFC5;
    }

    display: flex
  }
  .search-field-devices {
    height: 30px;
    margin-left: 9px;
    border: 1px solid #5179a5;
      .icon {
        position: absolute;
        bottom: 28px;
        left: 196px;
      }

  }

}

.search-field-finance {
  height: 30px;
  margin-left: 9px;
  border: 1px solid #5179a5;
    .icon-finance {
      position: absolute;
      bottom: 31px;
      left: 222px;
      width: 25px;
      height: 23px;
    }

}

.search-field-clinical_and_devices {
  height: 30px;
  margin-left: 9px;
  border: 1px solid #5179a5;
    .icon {
      position: absolute;
      bottom: 30px;
      left: 214px;
    }
    input {
      color: #99AFC5;
    }
}
@import '../_colors.scss';

.reset-wizard {
  display: flex;
  align-items: center;
}

.reset-wizard-wrapper {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.reset-wizard-button {
  background-image: url(/images/reset_wizard.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 35px;
}

.reset-wizard-text {
  color: white;
}

.reset-wizard-border-line {
  border-right: 1px solid white;
  height: 30px;
  padding-left: 15px;
}

.wizard-sidebar-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  //  border-top: 1px solid $grey-text;
}

.patient-wizard-title {
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  width: 200px;
  margin: 20px auto;
  color: white;
}

.process-level-panel {
  height: 100%;
  width: 195px;
  display: flex;
  flex-direction: column;
  padding-top: 25%;
}

.process-level {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  height: 23%;
}

.process-step {
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  align-items: center;
}

.process-step-circle {
  width: 10px;
  height: 10px;
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  font-weight: bold;
  font-size: 13px;
  background-color: white;
  color: $light-blue;
  border: 2px solid $light-blue;

  &.sub-level {
    width: 2px;
    height: 2px;
    font-size: 11px;
  }

  &.selected {
    color: white;
    background-color: $green;
    border: 2px solid $green;
  }

  &.checked {
    color: white;
    background-color: $light-blue;
    background-image: url('/images/wizard_proccess_done.png');
    background-repeat: no-repeat;
    background-position: center;
    // border: none;
    background-size: 50%;
  }
}

.process-step-line {
  height: calc(100% - 20px);
  width: 2px;
  background-color: $light-blue;
}

.process-content {
  display: flex;
  flex-direction: column;
}

.process-title {
  text-transform: uppercase;
  color: $grey-text;
  font-size: 13px;
  padding-top: 5px;
  margin-bottom: 10px;

  &.selected {
    font-weight: bold;
    color: $green;
  }

  &.sub-process {
    padding-top: 0px;
    margin-bottom: 0px;
    padding-left: 10px;
  }
}

.process-description {
  color: $grey-text;
  font-size: 13px;
  line-height: 20px;
}

.sub-process-wrapper {
  display: flex;
  flex-direction: row;
}

.sub-proccess-level-wrapeer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  right: 24px;
}

.sub-proccess-level {
  display: flex;
  margin: 20px 0px;
  align-items: center;

  &.not-selected {
    opacity: 0.5;
  }
}

.sub-proccess-level-line {
  width: 30px;
  align-self: center;
  border-bottom: 2px dashed $light-blue;
}

.sub-proccess-title {
  color: white;
  margin-left: 10px;
}

/* Select game/device/range page */

.wizard-back-button-wrapper {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 20px;
  color: $text_color;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 20px;
}

.wizard-back-button-img {
  background-image: url('/images/wizard_back_arrow.png');
  height: 16px;
  width: 11px;
  background-repeat: no-repeat;
  margin-top: 3px;
}

.select-header {
  text-align: center;
  //  display: flex;
  flex-direction: column;
  //  height: 80px;
  //  justify-content: center;
  margin: 0 auto;
}

.select-mini-title {
  text-transform: uppercase;
  font-size: 10px;
  color: $text_color;
  margin-top: 10px;
}

.select-welcome {
  font-size: 22px;
  font-weight: bold;
  color: $text_color;
  // margin-top: 5px;
}

.exit-wizard-button {
  position: absolute;
  right: 0;
  top: 3%;
  cursor: pointer;
  background-image: url(/images/exit_wizard.png);
  background-repeat: no-repeat;
  width: 5%;
  height: 3%;
  background-size: contain;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.selection-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  //margin-top: 1%;
  //  justify-content: space-around;
  //  background-color: white;
  //  margin: 0 5%;
  //  padding: 5% 0;
  overflow-y: auto;
}

.evaluation-selection {
  display: flex;
  justify-content: space-around;
  background-color: white;
  margin: 0.5% 2%;
  padding: 3.5% 0;
  height: 100%;

  &.noBG {
    background-color: transparent;
  }

  &.noHeight {
    height: unset;
  }

  &.noPadding {
    padding: unset;
  }
}

// @media only screen and (max-width: 1440px) {
//   .evaluation-selection {
//     padding: 0 0;
//   }
// }

.selection-box {
  border: 1px solid #8caace;
  padding: 30px;
  box-shadow: 0px 7px 37px rgba(233, 243, 255, 0.59);
}

.gouge-wrapper {
  background-color: white;
  //height: fit-content;

  //width: 40%;
  &.vertical {
    flex-direction: row;

    justify-content: center;
    align-items: flex-start;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.svg-wrapper {
  //height: 290px;
  width: 480px;
  //  background-color: white;
}

.gouge-texts {
  margin-top: 231px;
  // padding-left: 143px;
  position: absolute;
  color: #6da9ff;
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: space-around;
}

.gouge-delta-angle {
  width: 505px;
  padding-top: 115px;
  position: absolute;
  font-size: 100px;
  color: #3EC880;
  text-align: center;
}

.gouge-left-text {
  padding-left: 150px;
  position: absolute;
}

.gouge-left-label {
  margin-left: 3px;
  letter-spacing: 1px;
  font-size: 18px;
}

.gouge-min-angle {
  margin-left: 3px;
  text-align: center;
  height: 23px;
}

.gouge-right-text {
  padding-left: 400px;
  position: absolute;
}

.gouge-right-label {
  margin-left: 3px;
  letter-spacing: 1px;
  font-size: 18px;
}

.gouge-max-angle {
  margin-left: 3px;
  text-align: center;
  height: 23px;
}


.gouge-box {

  //margin-top: -800px;
  &.rotated {
    transform: scaleX(-1);
  }
}

.device-type-gouge {
  position: absolute;
  background-color: transparent;
  width: 50%;
  height: 50%;
  color: blue;
}

.gouge {
  background-color: $gouge-blue;
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  color: black;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gouge-title {
  color: black;
}

.gouge-angles {
  color: white;
  font-weight: bold;
  position: relative;
  // padding-bottom: 780px;
  padding-right: 550px;
  font-size: 24px;
  //   font-weight: bold;
}

.box-selection-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 100%;

  &.vertical {
    flex-direction: column;
  }
}

.wizard-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 300px;
  max-height: 300px;
  min-width: 260px;
  max-width: 380px;
  width: calc(100% / 3 - 80px);
  //margin: 5px 15px;
  box-shadow: 0px 7px 37px rgba(233, 243, 255, 0.59);

  &.selected {
    border: 2px solid $green;
    height: 296px;
    width: calc(100% / 3 - 84px);
  }
}

.selected-wizard-box-img {
  width: 24px;
  height: 24px;
  border-radius: 17px;
  padding: 5px 0;
  background-color: $green;
  background-image: url('/images/wizard_proccess_done.png');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  background-size: 12px;
  right: -14px;
  top: -14px;
  z-index: 10;

  &.deleteable {
    background-image: url('/images/wizard_proccess_delete.png');
    background-color: $red;
  }
}


.wizard-img-array-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
}

.wizard-img-full {
  height: 163px;
  width: 146px;
}

.wizard-img {
  margin-top: 30px;

  // max-height: calc(100% - 90px);
  // max-width: 80%;
  &.wizard-small-image {
    height: 90px;
    margin: 10px 0px;
    //   max-height: none;
    //   max-width: none;
  }

  &.array {
    position: relative;
    left: -50%;
    max-height: 100%;
    max-width: 100%;
  }

  &.full-image {
    margin-top: 0px;
    width: 194px;
    height: 194px;
  }
}

.wizard-box-button {
  position: absolute !important;
  bottom: 40px;
  width: 80%;
  background-color: #3EC880 !important;
  color: white !important;
  text-align: center;
  font-weight: bold !important;

  &.small {
    bottom: 0;
    width: 100%;
    margin-top: 0px;
    background-color: #6da9ff !important;
    border-radius: 0 !important;

    &.selected {
      background-color: #3fc880 !important;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.wizard-box-notes-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  position: absolute;
  bottom: 90px;
}


.wizard-box-notes {
  color: #b1b6bb;
  font-size: 11px;
  position: absolute;
}

.wizard-box-miniImage {
  position: absolute;
  height: 100px;
  margin-top: -215px;
  right: -21px;
}

.wizard-box-delete-button {
  height: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.wizard-header-warning {
  text-align: center;
  color: $red;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  height: 20px;
  margin-top: 10px;
}

.game_iframe_wrapper {
  z-index: 10;
  width: 100%;
  height: 100%;
  //  position: absolute;
  border: none;
  overflow: hidden;
  &.clickable {
    cursor: pointer;
  }
}

.game-iframe {
  z-index: 10;
  width: 100%;
  height: 100%;
  //  position: absolute;
  border: none;

  &.flipView {
    transform: rotateY(180deg);
  }
}

.box-selection-wrapper {
  height: 100%;
  overflow-y: auto;
}

.disable-wizard-for-patient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: none;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 10000;
  cursor: not-allowed;

  &.patient-remote {
    background-color: rgba(255, 255, 255, 0.0);
  }

  &.modal-overlay {
    z-index: 2000;
  }

  &.wait {
    cursor: wait;
  }

  &.over-modal {
    z-index: 2500;
    background-color: transparent;
  }
}


.disable-evaluation-for-patient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: none;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 10000;
  cursor: not-allowed;

  &.patient-evaluation {
    background-color: rgba(255, 255, 255, 0.0);
  }

  &.modal-overlay {
    z-index: 2000;
  }

  &.wait {
    cursor: wait;
  }

  &.over-modal {
    z-index: 2500;
  }
}

.therapist_subscriber_container_wrapper {
  &.clickable {
    cursor: pointer;
  }
}

.therapist_game_view_in_remote_patient_wrapper {
  height: 200px !important;
  width: auto !important;
  margin: 0px auto;
}

.therapist-subscriber-container-in-main-window {
  width: 100% !important;
  height: 100% !important;
}

#patient-subscriber-container-in-sidebar {
  height: 200px !important;
  width: auto !important;
  margin: 0px auto;
  z-index: 2;
}

#patient-publisher-container-in-sidebar {
  // height: 200px !important;
  width: auto !important;
  // margin: 0px auto;
}

.therapist-subscriber-container-in-sidebar {
  // height: 200px !important;
  width: auto !important;
  // margin: 0px auto;
}

#therapist-publisher-container-in-sidebar {
  // height: 200px !important;
  width: auto !important;
  // margin: 0px auto;
}

.patient-wizard-process-level-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.in-session {
    min-height: calc(100% - 200px);
  }
}

.next-step-buttons-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  margin: 10px 0px;
}

.next-step-button {
  height: 50px !important;
  width: 250px !important;
  font-size: 17px !important;
  cursor: pointer;
  background-color: #3EC880 !important;
  color: #FFFFFF !important;
  cursor: pointer;

  &.secondary {
    color: white;
    background-color: $green;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.refresh {
    background-color: $light-blue;
  }
}

.start-game-page-container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  background-color: black;
}


.gameMenu .MuiTypography-root {
  margin-left: 25px;
}


.game-timer-wrapper {
  z-index: 20;
  position: absolute;
  top: -5px;
  //background-color: #273f5b;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  left: 3%;
  font-size: 65px;
  font-family: "Helvetica", sans-serif;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #283f5a;
}

.game-score-new {
  z-index: 20;
  position: absolute;
  top: -5px;
  //background-color: #273f5b;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  right: 3%;
  font-size: 65px;
  font-family: "Helvetica", sans-serif;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #283f5a;
}

.wizard-page-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.bottom-panel-game-buttons {
  z-index: 20;
  position: absolute;
  bottom: 20px;
  display: flex;
  //justify-content: center;
  //height: 80px;
  //align-items: center;
  left: 20px
}

.play-button {
  background-image: url(/images/play.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 55px;
  width: 55px;
  cursor: pointer;
}

.pause-button {
  background-image: url(/images/pause.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 55px;
  width: 55px;
  cursor: pointer;
}

.stop-button {
  background-image: url(/images/stop.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 55px;
  width: 55px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.game-sensitivity-wrapper {
  z-index: 20;
  position: absolute;
  bottom: 20px;
  right: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .game-sensitivity-bottom-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .game-sensitivity-horizontal-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      background: rgba(63, 81, 181, 0.5);
      color: white;
      border-radius: 35px;
      margin-right: 10px;

      .game-sensitivity-slider {
        width: 150px;
        height: 0px;
      }

      .game-sensitivity-slider-text {
        padding: 0 10px;

        &.right {
          width: 35px;
        }
      }
    }
  }

  .game-sensitivity-vertical-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px;
    margin-right: 6px;
    background: rgba(63, 81, 181, 0.5);
    color: white;
    border-radius: 35px;
    margin-bottom: 10px;

    .game-sensitivity-slider {
      width: 0px;
      height: 150px;
    }

    .game-sensitivity-slider-text {
      padding: 10px 0;
    }
  }
}

.game-sensitivity-slider-trackBefore {
  background-color: white !important;
}

.game-sensitivity-slider-trackAfter {
  background-color: black !important;
}

.game-sensitivity-slider-thumb {
  background-color: white !important;
}

.game-sensitivity-slider-disabler {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.reset-settings-button {
  border: 2px solid $green_button;
  font-size: 15px;
  color: $green_button;
  height: 36px;
  margin-top: 6px;
  padding: 0px 15px 0px 32px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/images/back_settings.png);
  background-repeat: no-repeat;
  background-size: 17px 14px;
  background-position: 5px center;
  position: absolute;
  bottom: 20px;
  right: 20px;

  &.patient {
    bottom: initial;
  }

  &.DRS {
    right: 150px;
  }
}

.bottom-panel-game-button {
  height: 64px;
  width: 64px;
  cursor: pointer;
  padding-left: 20px;
  cursor: pointer;
  position: absolute;
  bottom: -8px;
  left: 53px;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.play {
    background-image: url(/images/GameStart_64px.png);
  }

  &.pause {
    background-image: url(/images/GamePause_64px.png);
  }

  &.stop {
    background-image: url(/images/GameStop_64px.png);
  }

  &.settings {
    background-image: url(/images/GameSettings_64px.png);
  }

  &.DRS {
    background-image: url(/images/GameRange_64px.png);
  }

  &.treatments {
    background-image: url(/images/GameMenu_64px.png);
    //position: absolute;
    //bottom: 10px;
    //right: 20px;    
  }
}

// .MuiButton-fab-18 {
//   height: 86px !important;
//   width: 86px !important;
// }


.MuiButton-containedPrimary-13 {
  background-color: #263f5c !important;
}

.MuiButton-containedPrimary-153 {
  background-color: #263f5c !important;
}

.determine-primary-range {
  height: calc(100% - 80px);
}

// one page in wizard - setbodyparts:
.primary-selection-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  position: relative;
  background-color: white;

  &.not-first {}

  &.hand {}
}

.axis-wrapper {
  display: flex;
  align-items: center;
  //margin: 0 20px;
  //width: 120px;
  justify-content: center;

  &.hand {
    height: 30px;
    margin-top: 10px;
    // width: 110px;
  }
}

.axis-img {
  background-repeat: no-repeat;
  background-size: contain;

  &.vertical {
    background-image: url(/images/vertical.png);
    width: 20px;
    height: 20px;
  }

  &.horizontal {
    background-image: url(/images/horizantal.png);
    width: 17px;
    height: 13px;
  }
}

.axis-name {
  color: #33608C;
  text-transform: uppercase;
  margin-left: 5px;
}

.body-parts-selection-wrapper {
  display: flex;
  height: 73px;
  margin-bottom: 20px;
  //  width: 355px;
}

.body-parts-wrapper {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-weight: bold;
  color: $blue_text_color;
  height: 220px;
  flex-wrap: wrap;
  margin-left: 33px;
  margin-top: 27px;

  &.twoCol {
    width: 315px;
  }
}

.body-part-name {
  margin-top: 2px;
  background-color: #6da9ff !important;
  color: white !important;
  font-size: 15px;
  color: white;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;

  &.disabled {
    background-color: #3fc880 !important;
    cursor: not-allowed;

    &.butAllowed {
      cursor: pointer;
    }
  }
}

.body-part-side-selection {
  display: flex;
  flex-direction: row;
  border: 2px;
  border-color: #33608C;
}

.body-part-side {
  background-color: #6da9ff;
  font-size: 15px;
  color: white;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 20px;
  width: 17%;
  min-width: 140px;
  font-weight: bold;

  &.disabled {
    background-color: #afc83f;
    cursor: not-allowed;
  }
}

.wizard-box-small {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 199px;
  height: 222px;
  box-shadow: 0px 7px 37px rgba(233, 243, 255, 0.59);
  border: 2px solid #6da9ff;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  &.selected {
    border: 2px solid $green;
  }
}

.wizard-box-mid {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 300px;
  height: 300px;
  margin: 15px;

  //box-shadow: 0px 7px 37px rgba(233, 243, 255, 0.59);
  &.selected {
    border: 2px solid $green;
    margin: 13px;
  }


  &.selectedUnpair {
    border: 2px solid $red;
    margin: 13px;
  }

  &.threedt {
    /* height: 290px; */
    width: 210px;

    &.search {
      // height: 100px;
      // margin-top: 100px;
      // margin-bottom: 100px;
    }
  }
}

.searching {
  padding-top: 77px;
  font-size: 22px;
  color: #659aff;
  text-transform: capitalize;
}

//3 dot animation
@keyframes blink {
  0% {
    opacity: .2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}

.threeDots span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.threeDots span:nth-child(2) {
  animation-delay: .2s;
}

.threeDots span:nth-child(3) {
  animation-delay: .4s;
}

//3 dot end animation

.determine-range-gouge-wrapper {
  display: flex;
  width: 100%;
  //  height: calc(100% - 30px);
  align-items: center;
  margin: auto 0;
  z-index: 999999;
}

.hover {
  position: relative;

  &:hover &__no-hover {
    opacity: 0;
  }

  &:hover &__hover {
    opacity: 1;
  }

  &__hover {
    position: absolute;
    top: 0;
    opacity: 0;
  }

  &__no-hover {
    opacity: 1;
  }
}


.carousel-ext-wrapper {
  //  width: 100%;
  // margin-left: 147px;
  //margin-right: 20px;
  display: flex;
  //justify-content: center;
  background-color: white;
  margin-left: auto;
  margin-right: auto;

  &.hand-tutor {
    position: relative;
    top: 83px;
  }
}

.hand-tutor-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 150px);
}

.hand-selection-wrapper {
  display: flex;

  &.column {
    flex-direction: column;
  }
}

.fingers-wrapper {
  width: 100%;
  display: flex;
  height: 30px;
  margin-top: 20px;
}

.fingers-wrap {
  position: relative;

  &.left {
    transform: scaleX(-1);
  }
}

.finger-selection {
  flex-grow: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
}

.finger-cb {
  // width: 100px;
  // height: 100px;
}

.little-pos {
  padding-left: 13px;
  margin-top: -512px;
  width: 24px;

  &.left {
    padding-left: 245px;
    margin-top: -510px;
  }
}

.ring-pos {
  padding-left: 66px;
  margin-top: -125px;
  width: 24px;

  &.left {
    padding-left: 192px;
    margin-top: -126px;
  }
}


.middle-pos {
  padding-left: 121px;
  margin-top: -95px;
  width: 24px;

  &.left {
    padding-left: 137px;
    margin-top: -98px;
  }
}

.index-pos {
  padding-left: 173px;
  margin-top: -52px;
  width: 24px;

  &.left {
    padding-left: 85px;
    margin-top: -55px;
  }
}

.thumb-pos {
  padding-left: 242px;
  margin-top: 73px;
  width: 24px;

  &.left {
    padding-left: 11px;
    margin-top: 72px;
  }
}

.finger-checkbox {
  position: absolute;
  background-image: url(/images/fingers_checkbox.png);
  width: 20px;
  height: 20px;
  margin: 5px;
  background-repeat: no-repeat;
  background-size: cover;

  &.checked {
    background-image: url(/images/fingers_checkbox_checked.png)
  }
}

.finger-name {
  text-transform: uppercase;
  color: $blue_text_color;
  text-align: center;
  font-size: 23px;
  padding-left: 12px;
  padding-top: 10px;
}

.wrist-delta-value {
  padding-left: 5px;
  padding-left: 180px;
  margin-top: -72px;
  font-size: 23px;
  color: #33608C;

  &.left {
    transform: scaleX(-1);
    margin-right: 65px;
  }
}

.wrist-delta-value-2D {
  padding-left: 5px;
  padding-left: 175px;
  margin-top: 235px;
  font-size: 23px;
  color: #33608C;

  &.left {
    transform: scaleX(-1);
    margin-right: 72px;
    margin-top: 230px;
    color: #33608C;
    font-size: 23px;
  }
}

.gouge-buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hand-gouge {
  background-color: white;
  background-position: center;
  background-image: url(/images/hand.svg);
  background-repeat: no-repeat;
  width: 400px;
  height: 400px;

  &.Left {
    transform: rotateY(180deg);
  }
}


.hand-gouge-eval {
  background-position: center;
  background-image: url(/images/hand.svg);
  background-repeat: no-repeat;
  width: 400px;
  height: 400px;
}

.new-hand-gouge-eval {
  background-position: center;
  background-repeat: no-repeat;
  width: auto;
  height: 480px;
  background-image: url(/images/HT100R.svg);
  background-color: white;

  &.game {
    margin-top: 57px;
    height: 470px;

    &.left {
      transform: scaleX(-1);
    }
  }
}

.select-device-wrapper {
  height: 100%;
}

.select-device-no-connected-devices-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.select-device-no-connected-devices {
  color: $blue_text_color;
  text-transform: uppercase;
  font-size: 50px;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 30%;
}

.select-eval-wrapper {
  height: 50%;
}

.select-title {
  font-size: 20px;
  color: #33608C;
  text-transform: uppercase;
  padding: 0% 2%;
  left: 0;
  width: 100%;

  &.hand {
    margin: 0px 0px;
  }
}

.set-device-page-content-wrapper {
  margin: 1%;
}

.set-device-page-bottom-buttons-wrapper {
  height: 30px;
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
}

.hand-options-and-gouge-wrapper {
  flex-direction: row;
}

.hand-options-wrapper {
  //  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  align-items: flex-start;
  position: relative;
  //margin-right: 15px;
  background-color: white;
}

.hand-gouge-wrapper {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.range-selection-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.fingers-vertical-selection-carousel {
  display: flex;
  justify-content: center;
  width: 100%
}


.game-over-title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: $blue_text_color;
  margin: 20px 0px;
  font-size: 20px;
}

.game-over-modal-body {
  width: 350px;
}

.game-over-modal-disabler {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: none;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;
  cursor: not-allowed;
}

.game-trophy {
  background-image: url(/images/game_trophy.png);
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.well-done-text {
  color: $blue_text_color;
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
  font-size: 22px;
}

.game-over-modal-statistics-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.game-over-modal-statistic {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.game-over-modal-statistic-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
}

.game-over-modal-statistic-header-image {
  background-repeat: no-repeat;
  background-position: 0px center;
  background-size: 15px 15px;
  padding: 10px;

  &.difficulty {
    background-image: url(/images/game_img_act.png);
  }

  &.score {
    background-size: 20px 20px;
    background-image: url(/images/game_score.png);
  }

  &.duration {
    background-image: url(/images/time_img_act.png);
  }
}

.game-over-modal-statistic-header-title {
  color: $blue_text_color;
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
}

.game-over-stat {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 40px;
  text-transform: uppercase;

  &.difficulty {
    color: $red;
  }

  &.score {
    color: $green;
  }

  &.duration {
    color: $green;
  }
}

.min {
  text-transform: uppercase;
  color: $light-blue;
  font-size: 11px;
}

.close-game-over-footer {
  height: 34px;
  background-color: $light-gray;
  text-align: center;
  color: $blue_text_color;
  font-size: 23px;
  padding-top: 5px;
  cursor: pointer;
}

.game-over-modal {
  width: 400px !important;
}

.index-finger {
  background-image: url(/images/index_finger.svg);
  height: 100px;
  width: 60px;
}

.spinner-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: white;
  opacity: 0.5;
  z-index: 1;
}

.bluetooth-icon-wrapper {
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bluetooth-icon-ripple {
  height: 94px;
  width: 94px;
  animation: ripple 2s linear infinite;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0 0 10px 10px #71b2fd;
  position: relative;
  top: 3px;
  left: 3px;
}

.bluetooth-icon {
  background-image: url(/images/bluetooth_button.svg);
  height: 100px;
  width: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 60px;
}

@keyframes ripple {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.refresh-search-bluetooth {
  background-image: url(/images/refresh_icon.png);
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  align-self: center;
  cursor: pointer;
  margin-right: 20px;
}

.clickable {
  cursor: pointer;
}

.container_remote_frame {
  // position:absolute;
  // top:0;
  // bottom:0;
  // right:0;
  // left:0;
  border: solid 5px red;

  // overflow:unset;
  &.SessionOff {
    //display:none;
    border: none;
  }
}

.dashboard_button {
  width: 100%;
  border-style: none;
  background-color: darkblue;
  font-size: 15px;
  color: white;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  background: #ffffff;
  position: fixed;
  width: calc(100% - 277px);
  bottom: 0px;
  height: 100px;

  .buttons {
    margin-top: 25px;
    display: flex;
    justify-content: space-evenly;

    .back_button {
      height: 50px !important;
      width: 250px !important;
      margin-left: 25px !important;
      font-size: 17px;
      cursor: pointer;
      background-color: #3EC880 !important;
      color: #FFFFFF;

      &.disabled {
        opacity: 0.5 !important;
        cursor: not-allowed !important;
      }
    }

    .back_button_range {
      height: 50px;
      width: 250px;
      background-color: #3EC880;
      margin-left: 25px;
      color: #FFFFFF;
      font-size: 17px;
      text-transform: uppercase;
      text-align: center;
      line-height: 50px;
      cursor: pointer;
    }

    .manage_bluetooth_devices_button {
      height: 50px;
      width: 300px !important;
      background-color: #3EC880;
      margin-left: 25px;
      color: #FFFFFF;
      font-size: 17px;
      text-transform: uppercase;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

//Evaluation flow
.range_motion_fingers_button {
  background: url(/images/Range-of-Motion-fingers.png) no-repeat;
  cursor: pointer;
  width: 136px;
  height: 136px;
  border: none;
  //  margin-right: 50px;
  color: blue;
  font-weight: bold;
}

.select_evaluation_button {
  border-style: none;
  background-color: #3cc87f;
  font-size: 15px;
  color: white;
  height: 30px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}


.range_motion_elbow_button {
  background: url(/images/Range-of-Motion-elbow.png) no-repeat;
  cursor: pointer;
  width: 136px;
  height: 136px;
  border: none;
  //    margin-right: 50px;
  color: blue;
  font-weight: bold;
}




.range_motion_knee_button {
  background: url(/images/Range-of-Motion-knee.png) no-repeat;
  cursor: pointer;
  width: 136px;
  height: 136px;
  border: none;
  //    margin-right: 50px;
  color: blue;
  font-weight: bold;
}


.motion_analysis_fingers_button {
  background: url(/images/Motion-Analysis-fingers.png) no-repeat;
  cursor: pointer;
  width: 136px;
  height: 136px;
  border: none;
  //    margin-right: 50px;
  color: blue;
  font-weight: bold;
}


.motion_analysis_wrist_button {
  background: url(/images/Motion-Analysis-wrist.png) no-repeat;
  cursor: pointer;
  width: 136px;
  height: 136px;
  border: none;
  //    margin-right: 50px;
  color: blue;
  font-weight: bold;
}


.motion_analysis_elbow_button {
  background: url(/images/Motion-Analysis-elbow.png) no-repeat;
  cursor: pointer;
  width: 136px;
  height: 136px;
  border: none;
  //    margin-right: 50px;
  color: blue;
  font-weight: bold;
}

.motion_analysis_knee_button {
  background: url(/images/Motion-Analysis-knee.png) no-repeat;
  cursor: pointer;
  width: 136px;
  height: 136px;
  border: none;
  //    margin-right: 50px;
  color: blue;
  font-weight: bold;
}


.dtutor_button {
  background: url(/images/evaldevice1.PNG) no-repeat;
  cursor: pointer;
  width: 200px;
  height: 200px;
  border: none;
}

.handtutor_button {
  background: url(/images/evaldevice2.PNG) no-repeat;
  cursor: pointer;
  width: 200px;
  height: 200px;
  border: none;
}

.bluetooth_connect_button {
  background: url(/images/evaldevice3.PNG) no-repeat;
  cursor: pointer;
  width: 200px;
  height: 200px;
  border: none;
}

.eval_button_lines_wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 90px;
}

// .back_eval_device_button{
//   width:300px;
//   border-style: none;
//   background-color: #3fc880;
//   font-size: 15px;
//   color: white;
//   height: 30px;
//   display: flex;
//   text-transform: uppercase;
//   cursor: pointer;
//   margin-left: 50px;
//   margin-right: 50px;
//   align-items: center;
//   justify-content: center;
//   &.disabled{
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// }

.back_eval_ma_device_button {
  width: 300px;
  border-style: none;
  background-color: #3fc880;
  font-size: 15px;
  color: white;
  height: 30px;
  display: flex;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 50px;
  margin-right: 50px;
  align-items: center;
  justify-content: center;
  margin-top: 28px;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

// .reset_eval_device_button{
//   width:300px;
//   border-style: none;
//   background-color: #3fc880;
//   font-size: 15px;
//   color: white;
//   height: 30px;
//   display: flex;
//   text-transform: uppercase;
//   cursor: pointer;
//   margin-left: 250px;
//   margin-right: 50px;
//   align-items: center;
//   justify-content: center;
//   &.disabled{
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// }


.eval_comment {
  width: 100%;
  height: 70px;
  display: inline-grid;
  flex-direction: row;
  margin-top: 20px;
  overflow: hidden;
  background-color: #fff;
  border: 3px solid #e5eeff;
  resize: none;
  padding: 4px;
  font-size: 16px;
}

.eval_info {
  padding: 5px 25px 5px 10px;
}

.eval_slider_button {
  width: 350px;
  border-style: none;
  font-size: 15px;
  color: white;
  height: 30px;
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


.image_text {
  display: flex;
  align-items: center;
  background-color: white;

  .text_after_image {
    text-align: left;

    .text_after_image_line {
      padding: 5px;
      font-weight: bold;
      color: #3F6EA3;
      font-size: 20px;
    }
  }
}

head {
  position: relative;
  padding-left: 10px;

  font-weight: 300;
  font-size: 27px;
  color: #ffffff;
  line-height: 39px;
  white-space: pre-wrap;
}

head:before {
  content: "";
  display: block;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
}

.welcome_table {
  &.wide {
    width: 55%;
    //  margin-right: 30px;
  }

  &.motion_analysis {
    width: 40%;
    min-width: 345px;
  }

}

.eval-slider {
  width: 100%;
  margin-bottom: -48px;
  margin-top: -14px;
  flex-grow: 1;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}



.ma_graphs_fingers_wrapper {
  height: 100%;
}

.ma_graph_wrapper {
  &.multi_graph {
    display: flex;
    align-items: center;
    margin-bottom: -40px;
    height: 21%;
  }

  .ma_graph_label {
    color: #3F6EA3;
    font-size: 17px;
    width: 10%;
    max-width: 2.5em;
  }
}



.primary-selection {
  display: flex;
  flex-direction: row;
  //background-color: white;
  margin: 0 33px;
}

.hand-options {
  display: flex;
}
@import '../_colors.scss';

.patient_ {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
}

.patient_side_bar_start_treatment_button_wrapper {
  margin-bottom: 20px;
  padding-left: 24px;
  padding-right: 2px;
}

.side_bar_content_wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 8%;
}

.side-bar-my-therapist-container {
  display: flex;
  flex-direction: column;
  width: 210px;
  padding-left: 25px;
}

.side-bar-my-therapist {
  color: white;
  font-size: 100%;
  padding-bottom: 5px;
  font-size: 15px;
  text-transform: uppercase;
}

.therapist_info {
  display: flex;
  flex-direction: row;
  min-height: 86px;
  margin-top: 5px;
}

.therapist_right_info_wrapper {
  display: flex;
  flex-direction: column;
}

.job_title {
  color: white;
  font-size: 12px;
  height: 25px;
}

.side_bar_therapist_img {
  width: 47px;
  height: 47px;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  border: 1px solid $light-blue;
  margin-top: 10px;
  margin-right: 15px;
}

.side_bar_therapist_location {
  display: flex;
  flex-direction: row;
  color: $grey-text;
  height: 25px;
  font-size: 12px;
}

.treatment_descripcion_wrapper {
  display: flex;
  flex-direction: row;
}

.treatment_descripcion_symbol {
  background-image: url(/images/star.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 12px;
  width: 12px;
  margin-right: 3px;
}

.treatment_descripcion {
  color: $grey-text;
  font-size: 12px;
}

.side_bar_button {
  width: 100%;
  height: 40px;

  &.clicked {
    background-color: $green_button_disabled;
    cursor: default;
  }

  &.removed {
    display: none;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  // &.remoted{
  //   content: "disconnect remote session";
  // }
}

// Session Log DataGrid

table#t01 caption {
  background-color: white;
  color: #6495ED;
  font-weight: bold;
  font-size: 20px;
  padding-right: 80px;
}

table#t01 tr:nth-child(even) {
  background-color: #D3D3D3;
}

table#t01 tr:nth-child(odd) {
  background-color: #A9A9A9;

}

table#t01 th {
  color: white;
  background-color: #6495ED;
}

table#t01 {
  width: 50%;
  background-color: #f1f1c1;
}

table#t02 caption {
  background-color: white;
  color: #6495ED;
  font-weight: bold;
}

table#t02 tr:nth-child(even) {
  background-color: #D3D3D3;
}

table#t02 tr:nth-child(odd) {
  background-color: #A9A9A9;

}

table#t02 th {
  color: white;
  background-color: #6495ED;
}

table#t02 {
  width: 50%;
  background-color: #f1f1c1;
}


.arrow_left {
  padding-right: 20px;
  display: inline;
}

.arrow_right {
  padding-right: 60px;
  display: inline;
}

.session_spacing {
  padding: 20px;
  display: inline-block;
}

.sessiongrid_activity_tables {
  display: flex;
}


.sidebar-menu-container {
  width: 235px;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  flex-direction: row;
  flex-grow: 1;
}

.side_bar_menu_wrapper {
  //padding-top: 10px;
  display: flex;
  flex-direction: column;
  //width: 215px;
  //margin-left: 25px;
  justify-content: space-around;

  &.no-border-top {
    border-top: none;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.side_bar_menu_link {
  text-decoration: none;
  color: unset;
  padding: 0 0 0 0 !important;
}

.selectedButton {
  background-color: #3fc880 !important;
}

.side_bar_menu_patient {
  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.side_bar_menu_symbol {
  height: 25px;
  width: 25px;
  background-size: contain;
  margin-left: 10px;
}

.side_bar_menu_text {
  color: white;
  font-size: 14px;
  margin-left: 8px;
  padding-top: 3px;
}

.main_wrapper_patient_dashboard {
  // position: absolute;
  // top: 0;
  // left: 0;
  //  height: 100%;
  // width: 100%;
  overflow: hidden;
}

.maxHeight {
  height: 100%;
}

.badges_wrapper {
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.patient_content_wrapper {
  margin: 25px;
}

.title_name {
  color: $blue_text_color;
  height: 40px;
  font-size: 17px;
  text-align: left;
  text-transform: uppercase;
}


.badge {
  background-color: white;
  height: 240px;
  width: 168px;
  margin: 0 10px 0 10px;
  box-shadow: 0px 7px 37px rgba(233, 243, 255, 0.59);
  display: table;
}

.badge_wrapper {
  height: 200px;
}

.badge_img_wrapper {
  position: relative;
}

.badge_content_wrapper {
  position: absolute;
  width: 100%;
  margin-top: 30%;
}

.badge_img {
  padding-top: 25px;
  width: 130px;
}

.badge_name {
  color: $blue_text_color;
  font-weight: bold;
  font-size: 20px;
  height: 30px;
  width: 160px;
  padding-bottom: 10px;
  box-sizing: content-box;
  text-transform: capitalize;
}

.badge_content {
  font-size: 36px;
  font-weight: bold;
  left: 1060px;
  top: 100px;
  color: green;
}

.badge_content_sub {
  font-size: 16px;
  font-weight: bold;
  left: 1060px;
  top: 40px;
  color: green;
}

.treatment_plan_appointments_wrapper {
  height: calc(100% - 310px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  box-sizing: content-box;
}


.my_treatment_shedual {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  height: calc(100% - 40px);

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}


.treatment_day_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 132px;
  box-shadow: 0px 7px 37px rgba(233, 243, 255, 0.59);
  position: relative;
  margin: 0 7px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  &.current_day {
    box-shadow: 0px 7px 37px rgba(233, 243, 255, 0.59);
  }
}

.treatment_day_previous_tasks {
  padding-top: 20px;
}

.calander_day_header {
  display: flex;
  background-color: #e3f0ff;
  height: 43px;
  align-items: center;
  color: $blue_text_color;
  font-size: 11.5px;

  &.current_day {
    background-color: $my_treatment_header_current_day;
    color: white;
  }

  &.today {
    background-color: #5079a5;
    color: white;
  }
}

.calander_day_number {
  font-size: 34px;
  padding-left: 10px;
  margin-right: 2px;
  float: left;
}

.calander_mounth {
  opacity: 0.5;
  right: 11px;
  position: absolute;
  top: 7px;
}

.patient_calander_day {
  text-transform: uppercase;
  position: absolute;
  top: 25px;
  right: 10px;
}

.calander_day {
  text-transform: uppercase;
  position: absolute;
  top: 22px;
  right: 10px;
}

.treatment_day_menu {
  height: calc(100% - 43px);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  color: $blue_text_color;

  &.no-treatments {
    opacity: 0.5;
  }

  &.modal {
    flex-direction: row;
    padding: 5px;
  }
}

.menu_opt_wrapper {
  flex-grow: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  justify-content: center;

  &.current_day {
    color: $purple;
  }

  &.current_day_duration {
    color: $green_button;
  }
}

.menu_opt_wrapper_footer {
  height: 43px;
  background-color: $gray;
  width: 100%;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 13px;

  &.clickable {
    cursor: pointer;
  }
}

.treatment_menu_img {
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin: 5px 0px;

  &.pending {
    cursor: pointer;
  }
}

.upcoming_appointments_head_wrapper {
  margin-bottom: 13px;
  position: relative;
}

.treatment_table_header {
  display: flex;
  justify-content: space-between;

  .title {
    margin-bottom: 13px;
  }
}

.treatment_table_header_button {
  top: -8px !important;
}

.upcoming_appointments_title_name {
  color: $blue_title;
  height: 40px;
  font-size: 17px;
  text-transform: uppercase;
}

.therapist_appointment_wrapper {
  height: 100%;
}

.appointments_list {
  overflow-y: auto;

  &.maxInView_5 {
    max-height: 375px
  }
}

.no_upcoming_appointments {
  height: 100px;
}

@media screen and (max-height: 700px) {
  .badges_wrapper {
    height: 250px;
  }

  .badge {
    height: 200px;
  }

  .badge_wrapper {
    height: 160px;
  }

  .badge_img {
    padding: 20px 0px;
    width: 120px;
    height: 120px;
  }

  .menu_opt_wrapper_footer {
    height: 20px;
  }

  .treatment_plan_appointments_wrapper {
    height: calc(100% - 270px);
  }
}
@import '../_colors.scss';

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.nochrome{
  
  text-align:center;
  font-size:19px;
  margin-top: 13%;
  margin-top: 50px;
  
  }
  
.browsers{
  display: flex;
  flex-direction: row;
  margin: 50px 0 50px 0;
  justify-content: space-around;
}


  .chromeimg
  {
    background: url(/images/chrome.ico) no-repeat;
    width: 80px;
    height:80px;
    margin:10px auto;
    background-size:cover;
  
  }

  .qqimg
  {
    background: url(/images/qq.png) no-repeat;
    width: 80px;
    height:80px;
    margin:10px auto;
    background-size:cover;
  
  }

.browsers_text{
  color: #33608c;
  font-size: 15px;
  font-weight: 600;
}

.main_wrapper{
  height: 100%;
  width: 100%;
  min-width: 550px;
  min-height: 500px;
  background-image: url(/images/Login_bg_footer.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: $screen_background;
  background-size: contain;
  position: absolute;
  top: 0; left: 0;
}

.login_container{
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 550px;
  background-color: white;
  box-shadow: 0px 7px 37px rgba(6, 57, 128, 0.72);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.login_header{
  height: 125px;
  width: 550px;
  background-color: $header_background;
  background-image: url(/images/header_bg.png);
  background-repeat: no-repeat;
  background-size: 550px 125px;
  height: 125px;
  width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_img{
  background-image: url(/images/MediTouchLogo_250x76px_White_180105.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 250px;
  height: 76px;
}

.user_input_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  margin: 0 auto;
}

.user_input_wraper{
  margin-top: 41px;
  width: 359px;
  margin-left: 20px;
}

.input_container{
  display: flex;
  flex-direction: row;
  margin: 2px;
  margin-bottom: 37px;
}

.input_img{
  background-repeat: no-repeat;
  height: 32px;
  width:32px;
  background-position: center;
  background-size: cover;
}

.input_box{
  border-top: none;
  border-left: none;
  border-right: none;
  border-width: 1px;
  border-color: $text_color;
  margin-left: 12px;
  width: 100%;
  font-size: 14px;
  color: $text_color;
  padding-left: 12px;
}

    .input_box:focus {
        box-shadow: 0 0 5px rgba(81, 203, 238, 1);
        border: 1px solid rgba(81, 203, 238, 1);
    }

.input_box::-webkit-input-placeholder {
   color: $text_color;
}

.log_in_button{
  border-style: none;
  background-color: $green_button;
  font-size: 17px;
  font-weight: bold;
  color: white;
  height: 40px;
  margin-top: 20px;
  margin-left: 6px;
  width: calc(100% - 6px);
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
  
  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.login_footer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-left: 1px;
  visibility: hidden;
}


.footer_text{
  font-size: 16px;
  color: $text_color;
  opacity: 0.6;
}

.remember_me_check_box{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.check_box_wrapper{
  // background-image: url(/images/remember_me.png);
  width: 25px;
  height: 25px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  &:hover {
    cursor: pointer;
  }
}

#right_text{
  position: relative;
  left: 70px;
  &:hover {
    cursor: pointer;
  }
}

.error-msg{
  font-size: 15px;
  color: red;
  text-align: center;
  margin-top: 25px;
}

.version_number{
  font-size: 13px;
  margin-top: 61px;
  width: inherit;
  color: #283f5a5c;
  justify-content: space-between;
  display: flex;
  .version_number_text{
    padding: 20px;
  }
}

    .report-filter {
      width: 50%;
      background-color: white;
      height: 85.6%;
      overflow: scroll;
    }

    .report-footer {
      clear: both;
      background: #ffffff;
      padding: 0;
      text-align: center;
      line-height: normal;
      position: fixed;
      width: calc(100% - 287px);
      bottom: 0px;
      height: 100px;
      z-index: 0;
      display: flex;
      justify-content: space-evenly;
    }


    .eval-report-graph-header {
      color: #33608c;
      font-size: 17px;
      text-transform: uppercase;
      background-color: #f1f7ff;
      text-align: center;
      padding: 10px 0px;
    }


    .eval-graphical-area {
      // margin-left: 900px;
      // margin-top: 120px;
      // width: 700px;
      //  height: 400px;  
      //  position: absolute;
      background-color: #f1f7ff;
      padding: 10px 0px;
    }

    .eval_commit_hand_rom {
      // margin-left: 900px;
      // margin-top: 540px;
      width: -webkit-fill-available;
      height: 40px;
      background-color: #ffffff;
      border: 2px solid #e5eeff;
      outline: none;
      resize: none;
      margin: 0px 24px 24px 24px;
    }

    .eval_record_info_hand_rom {
      //  margin-left: 900px;
      //  margin-top: 660px;
      //  width: 700px;
      //  height: 30px;  
      //  position: absolute;
      background-color: #f1f7ff;
      color: #33608c;
      font-size: 17px;
      text-align: center;
      margin: 0px 24px 0px 24px;
      padding-top: 10px;
      padding-bottom: 10px;
    }


    .eval_graph_unique_description {
      //  margin-left: 900px;
      //  margin-top: 710px;
      //  width: 700px;
      //  height: 40px;  
      //  position: absolute;
      background-color: #f1f7ff;
      color: #33608c;
      font-size: 17px;
      text-align: center;
      margin: 0px 24px 0px 24px;
      padding-bottom: 10px;
      text-transform: none !important;
    }


    .eval_report_print_button {
      height: 50px;
      width: 250px;
      background-color: #3EC880;
      margin-left: 25px;
      color: #FFFFFF;
      font-size: 17px;
      text-transform: uppercase;
      text-align: center;
      line-height: 50px;
      cursor: pointer;
      cursor: pointer;
      margin-top: 25px;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }


    .GraphTitle {
      font-size: 20px;
      text-align: center;
      color: #33608c;
    }

    .report-back {
      height: 50px;
      width: 250px;
      background-color: #3EC880;
      margin-left: 25px;
      color: #FFFFFF;
      font-size: 17px;
      text-transform: uppercase;
      text-align: center;
      line-height: 50px;
      cursor: pointer;
      margin-top: 25px;
    }

    .eval-graph-legend-col-title {
      padding-left: 135px;
      font-size: 12px;
    }

    .eval-graph-legend-box {
      padding-left: 40px;
      font-size: 12px;
    }

    .legend-row-wrapper {
      display: flex;
      flex-direction: row;
    }

    .eval-report-print-date {
      //  padding-left: 700px;
    }

    .print-template-padding {
      margin-bottom: 200px;
      position: absolute;
    }

    .print-template-graph-legend {
      // display: flex;
      // flex-direction: row;
      // position: absolute;
    }

    .clinic-logo-image {
      height: 100px;
      width: 100px;
      max-width: 100px;
      max-height: 100px;
    }

    .eval-report-custom-time {
      display: flex;
      flex-direction: row;
    }

    .eval-date {
      &.to {
        text-decoration: underline;
        padding-left: 7px;
      }

      &.from {
        text-decoration: underline;
        padding-right: 7px;
      }

      &.todayOnly {
        margin-left: -40px;
      }
    }

    .print-template-header {
      display: flex;
      flex-direction: row;
      padding: 40px 50px 0 50px;
      justify-content: space-between;
    }

    .print-template-title {
      text-transform: uppercase;
    }

    .report-content-to-pdf {
      width: 595.28pt; //a4 width
      display: flex;
      flex-direction: column;
      // border: solid;
      // background: white;
      // top: 0px;

      &.dev {
        top: 0px;
        background: white;
        border: 1px solid;
      }
    }

    .multi-checkbox-slection {
      position: absolute;
      right: 4.7%;
      padding-top: 38px;
      z-index: 100;
      display: flex;
      align-items: center;
    }


    .dialog_checkbox_image_eval {
      position: relative;
      font-size: 16px;
      width: 20px;
      height: 28px;
      display: inline-block;
      line-height: 24px;
      background-color: transparent;
      font-family: Roboto, sans-serif;
      transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      cursor: pointer;
      border: none;
    }

    table {
      border-collapse: collapse;
    }

    table,
    td,
    th {
      //border: 1px solid black;
    }


    .my-profile-title {
      font-size: 20px;
      color: #33608C;
      text-transform: uppercase;
    }

    .report-select {
      display: flex;
      flex-direction: row;
      //width: 100%;
      // height: auto;
      //background-color: white;
      justify-content: flex-start;

      .image-container {
        width: 15%;
        display: inherit;
        flex-direction: column;
        padding: 45px 50px;

        .image-buttons {
          height: auto;
          width: 230px;
          cursor: pointer;
          color: white;
          display: flex;
          user-select: none;

          .button {
            width: 115px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background-color: #71B2FD;

            .seperator {
              position: relative;
              background-color: white;
              width: 1px;
              height: 70%;
              top: 4px;
            }

            .image-preview {
              width: 230px;
              height: 230px;
              background-color: #2E4460;
              //        margin: 0 auto;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;


            }
          }
        }
      }

      .content {
        width: 100%;
        height: auto;
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 60px;
          line-height: 30px;

          .name {
            color: #33608c;
            font-size: 17px;
            text-transform: capitalize;
          }

          .email {
            color: #9eb1c6;
            font-size: 17px;
          }

          .role {
            color: #9eb1c6;
            font-size: 17px;
          }
        }

        .image-buttons {
          width: 285px;
          height: auto;
          cursor: pointer;
          color: white;
          display: flex;
          user-select: none;

          .button {
            width: 160px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            margin-right: 25px;
            background-color: #71B2FD;
          }
        }
      }
    }

    .report-table {
      position: absolute;
      width: -webkit-fill-available !important;
      height: -webkit-fill-available;
      margin: 23px 0px;
      background-color: white;
      justify-content: space-around;
      padding: 0px 23px;

      .table-wrapper {
        width: auto !important;

        .eval-table-header {
          width: inherit;
          height: 50px;
          background-color: #5179A5;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          font-size: 20px;
          color: white;

          .DateClass {
            width: 20%;
            /* height: 40px; */
            background-color: #5179a5;
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-left: 20px;
          }

          .EvaluationClass {
            width: 45%;
            /* height: 40px; */
            background-color: #5179a5;
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .DeviceClass {
            width: 20%;
            /* height: 40px; */
            background-color: #5179a5;
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          .ShowGraphClass {
            width: 15%;
            /* height: 40px; */
            background-color: #5179a5;
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
    }


    .report-info {
      //display: flex;
      //flex-direction: row;
      //width: 50%;
      height: calc(100% - 220px);
      position: absolute;
      //background-color: white;
      justify-content: space-around;
      //width: max-content !important;
      width: 50%;

      .whitebackground {
        background-color: white;
      }

      .row {
        //width: 20%;
        //display: inherit;
        display: flex;
        flex-direction: row;
        //background-color: white;

        .input-wrapper {
          width: 220px;
          //padding-bottom: 19px;
          //display: flex;
          //padding-left: 50px;
          //flex-direction: row;
          padding: 2% 3%;

          & div:first-child {
            //       height: 25px;

            & div:first-child {
              height: 25px !important;
            }
          }

          hr {
            display: none;
          }

          input,
          .datepickercomp {
            width: 100%;
            height: 25px;
            outline: none;
            background-color: transparent;
            font-size: 17px;
            color: #01305f !important;
            border: 1px solid #a6a6a6;
            border-radius: 3px;
            padding: 3px 7px;
          }

          //DDL
          select {
            width: 220px;
            height: 34px;
            outline: none;
            background-color: transparent;
            font-size: 17px;
            color: #01305f !important;
            border: 1px solid #a6a6a6;
            border-radius: 3px;
            //padding: 3px 7px;
          }

          input:focus {
            box-shadow: 0 0 5px rgba(81, 203, 238, 1);
            border: 1px solid rgba(81, 203, 238, 1);
          }

          select {
            text-transform: capitalize;
            width: 100%;
          }

          .name {
            text-transform: capitalize;
            width: 100%;
            color: #33608c;
            font-size: 16px;
            font-weight: 600;
            padding-left: 2px;
            padding-top: 10px;

            &.mendatory {
              color: red;
            }

          }
        }

        .gender-input {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          font-size: 20px;
          text-transform: capitalize;
          margin: 10px 0px 3px -10px;

          input[type="radio"]:not(old) {
            opacity: 0;

            &:checked~.check::before {
              background: #71B2FD;
            }
          }

          label {
            display: flex;
            width: 115px;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #33608c;
            font-size: 17px;

            .check {
              display: flex;
              position: relative;
              border: 2px solid #AAAAAA;
              border-radius: 100%;
              height: 20px;
              width: 20px;
              z-index: 5;
              transition: border .25s linear;
              -webkit-transition: border .25s linear;
              justify-content: center;

              &::before {
                display: block;
                position: relative;
                content: '';
                border-radius: 100%;
                height: 12px;
                width: 12px;
                margin: auto;
                transition: background 0.25s linear;
                -webkit-transition: background 0.25s linear;
              }
            }
          }
        }

        textarea {
          height: 220px;
          width: 104%;
          resize: none;
          background-color: #f6faff;
          border: 0;
          font-size: 17px;
          color: #9eb1c6;

          &::-webkit-input-placeholder {
            font-family: "proxima-nova", sans-serif;
          }
        }
      }
    }


    .footer {
      background: #ffffff;
      position: fixed;
      width: calc(100% - 277px);
      bottom: 0px;
      height: 100px;

      .buttons {
        position: fixed;
        right: 2.5%;
        display: flex;
        margin-top: 25px;

        .button {
          height: 49px;
          width: 200px;
          background-color: #3EC880;
          margin-left: 25px;
          color: #FFFFFF;
          font-size: 17px;
          text-transform: uppercase;
          text-align: center;
          line-height: 49px;
          cursor: pointer;

          &.cancel {
            background-color: transparent;
            border: 2px solid #3EC880;
            color: #3EC880;
            height: 47px;
          }

          &.disabled {
            cursor: default;
            opacity: 0.5;
          }
        }
      }
    }

    .multi-table-text {
      color: #666;
      font-size: 12px;
      text-align: center;
    }
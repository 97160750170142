@import '../_colors.scss';
.therapist_dashboard_wrapper{
  //padding: 25px;
  background-color: #f0f7ff;
  height: calc(100% - 30px);
  overflow-y: auto;
}

.my_patients_header{
  margin-bottom: 10px;
  color: $blue_text_color;
  text-transform: capitalize;
  font-size: 17px;
  padding: 20px 20px 0 20px;
}

.my_patients_header_filter{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 10px 0;

  .my_patients_header_filter_element {
    font-size: 15px;
    color: #3c6fa4;
    cursor: pointer;

    &.selected {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.my_patient_contact{
  display: flex;
  flex-direction: row; 
  margin-top: 10px;
  height: 100px;
}

.my_patient_contact_img_wrapper{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  &.video{
    background-color: $video-contact;
  }
  &.mail{
    background-color: $mail-contact;    
  }
  &.chat{
    background-color: $chat-contact;    
  }
}

.my_patient_contact_img{
  height: 40px;
  width: 40px;
}


// css for PatientDetails
.my_patient_details_wrapper{
  cursor: pointer;
}

.my_patients_header_list{
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
}

.my_patients_header_list_loader{
  display: flex;
  margin-top: 50%;
  margin-left: 50%;
}

.my_patient_img_name_location_wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0;
  width: 100%;
  align-items: center;
  height: 60px;
  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.my_patient_img_logged_in{
  position: relative;
  min-width: 40px;
  width: 40px;
  height: 40px;
  background-position: center;
  background-size: contain;
  border: 3px solid #3cc880;
  border-radius: 100%;
  margin-right: 15px;
  box-shadow: 0 0 0 1px #5ac78f,
}

.my_patient_name_location_wrapper{
  display: flex;
  flex-direction: row;
  color: $blue_text_color;
  width: 100%;
  overflow:hidden;
  text-overflow:ellipsis;
    span {
      width: 100%;
      display: flex;
      justify-content: space-between;
        div {
          padding: 0 20px;
        }
    }
}

.my_patient_name{
  text-transform: capitalize;
  font-weight: bold;
  overflow:hidden;
  text-overflow:ellipsis;
}

.my_patient_location{
  display: flex;
  flex-direction: row;
}


.therapist_dashboard_selection{
  font-size: 15px;
  padding: 0 0 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .therapist_dashboard_select_option{
    color: $blue_text_color;
    cursor: pointer;
    padding: 0 10px;

    &.selected {
      color: $light-blue;
      cursor: default;
    }
  }

  .therapist_dashboard_select_title{
    color: $blue_text_color;
    cursor: default;
  }
}

// Graph
.therapist_box_title{
  color: $blue_title;
  height: 40px;
  font-size: 17px;
  text-transform: uppercase;
}

.therapist_graph{
  background: white;
}

.therapist_graph_selection_wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.therapist-graph{
  height: calc(100% - 110px);
  width: 90%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  background-color: #f1f7ff;
}


// Statistics and Appointments 
.statitstics_appointments_wrapper{
  flex-grow: 1;  
  display: flex;
  flex-direction: row;
}

.statistics_wrapper{
  height: 100%;
  flex-grow: 2;
}

.statistics_charts{
  display: flex;
  flex-direction: row;
  background: white;
  max-height: 375px;
  height: 100%;
}

.statistics_charts > div{
  width: 50%;
}

.whiteBackground{
background-color: white;
}

.image_eval_MA_info_fingers{
  margin-left: 800px;
}

.image_eval_MA_info_elbow{
  margin-left: 800px;
}


.record_time_label {
  padding-left: 3%;
}

.record_time_line {
  justify-content: space-between;
  display: flex;
  font-size: 22px;
  color: #3c6fa4;
  font-weight: bold;
}

.csv_download_button{
  color: inherit;
  text-decoration:none;
}

.record_time_slider {
  display:inline;
}

.evaluation-range-selector{
  width: 90%;
  height: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  &.disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.evaluation-range-selector-pre{
  font-size: 20px;
  color: turquoise;
  padding-right: 7px;
  display: flex;
  flex-direction: column-reverse;
}

.evaluation-selector-slider{
  width: 100%;
  margin-bottom: -48px;
  margin-top: -14px;
  flex-grow: 1;
}

.evaluation-range-selector-text{
  min-width: 90px;
  letter-spacing: 1px;
}

.settings-language-select-option {
    border: none;
    background: #303f9f;
    color: white;
    height: 40px;
    font-size: 17px;
    padding-left: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #5179a5;
}
@import '_colors.scss';
body {
  //padding: 50px;
  font-size: 14px;
  font-family: "proxima-nova",sans-serif;
  font-style: normal;
}

.carousel__slide-focus-ring{
  visibility: hidden;
}